import {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PageInfoContext } from 'contexts/PageInfoContext';
import DataStewardsTable from 'components/DataStewardsTable/DataStewardsTable';
import { getFromLocalStorage } from 'services/storageInterection';
import useDataStewardsDetails from 'fetch/fetch-hooks/data-stewards-hooks/useDataStewardsDetails';
import { UserDataContext } from 'App';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomTabPanel from './CustomTabPanel';

export type User = {
  name: string;
  active: DataStewardActiveStatus;
  email: string;
  country?: string;
  distributor_id?: string;
};

export type UpdatedUser = {
  name: string;
  email: string;
  active: number;
  role: string;
};

export type DataStewardActiveStatus = 1 | 0;

type UserRole = 'All' | 'Commercial Persona' | 'Data Steward';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DataStewardsPage({
  country,
  isOnboardingDataStewardsPage = false,
}: {
  country?: string;
  isOnboardingDataStewardsPage?: boolean;
}) {
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { setPageInfo } = useContext(PageInfoContext);
  const { authResult } = useContext(UserDataContext);

  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const { data, isLoading } = useDataStewardsDetails(
    selectedCountry,
    authResult
  );
  const roles: UserRole[] = ['All', 'Data Steward', 'Commercial Persona'];

  const usersRowData = useMemo(() => {
    const usersData = data?.data || [];
    return usersData.map((user: User, index: number) => ({
      ...user,
      idx: index + 1,
      id: index + 1,
    }));
  }, [data]);

  const filterUsersByRole = (role: UserRole) => {
    if (!usersRowData.length) return [];
    if (role === 'All') {
      return usersRowData;
    } else {
      return usersRowData.filter((user: User) => user.distributor_id === role);
    }
  };

  const containerStyles = (isOnboarding: boolean) => ({
    background: 'white',
    height: isOnboarding ? 'calc(100% - 68.5px)' : 'calc(100vh - 134px)',
    border: isOnboarding ? 'none' : '1px solid #e0e0e0',
    borderRadius: isOnboarding ? '0px' : '4px',
    overflow: 'hidden',
  });

  useEffect(() => {
    if (!isOnboardingDataStewardsPage) {
      setPageInfo({
        headerContent: 'Users',
      });
    }
  }, [isOnboardingDataStewardsPage, setPageInfo]);

  return (
    <>
      <Box sx={containerStyles(isOnboardingDataStewardsPage)}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            sx={{
              '& .MuiTab-root': {
                fontFamily: 'Helvetica Neue',
                minWidth: 0,
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Data Stewards" {...a11yProps(1)} />
            <Tab label="Commercial Personas" {...a11yProps(2)} />
          </Tabs>
        </Box>
        {roles.map((role, index) => (
          <CustomTabPanel
            key={index}
            value={value}
            index={index}
            isLoading={isLoading}
          >
            <DataStewardsTable
              dataStewards={filterUsersByRole(role)}
              country={selectedCountry}
              isOnboardingDataStewardsPage={isOnboardingDataStewardsPage}
            />
          </CustomTabPanel>
        ))}
      </Box>
    </>
  );
}

export default DataStewardsPage;
