import { Box, Chip } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useOnboarding } from "contexts/OnboardingContexts/OnboardingCountriesContext";
import OnboardingActionsMenuCell from "../OnboardingActionsMenuCell/OnboardingActionsMenuCell";
import formatDate from "utils/formatDate";
import CustomDatagridPagination from "components/CustomDatagridPagination/CustomDatagridPagination";
import {
  CountryType,
  OnboardingCountryStatus,
} from "pages/onboarding-page/types";
import CustomDataGridToolbarWithSearch from "components/CustomDataGridToolbarWithSearch/CustomDataGridToolbarWithSearch";
import { textData } from "constants/textConstants";

function OnboardingCountriesTable({
  resumeOnboarding,
}: {
  resumeOnboarding: (country: CountryType) => void;
}) {
  const {
    onboardingCountries,
    setOnboardingCountries,
    setIsCountryOnboarding,
    loading,
    setLoading,
  } = useOnboarding();

  const statusColorMap: { [key in OnboardingCountryStatus]: string } = {
    [OnboardingCountryStatus.InProgress]: "#35A0FE",
    [OnboardingCountryStatus.Onboarded]: "#66B512",
    [OnboardingCountryStatus.OnHold]: "default",
  };

  const columns: GridColDef<any>[] = [
    {
      field: "#",
      headerName: "#",
      filterable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.api.getAllRowIds().indexOf(params.id) + 1,
      flex: 0.1,
      sortable: false,
    },
    {
      field: "country",
      headerName: "Country Name",
      width: 200,
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "contributor",
      headerName: "Contributor",
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const status: OnboardingCountryStatus =
          params.value as OnboardingCountryStatus;
        const backgroundColor = statusColorMap[status];
        const textColor =
          params.value === OnboardingCountryStatus.OnHold
            ? "inherit"
            : "#FFFFFF";
        return (
          <Chip
            sx={{ background: backgroundColor, color: textColor }}
            size="small"
            variant="filled"
            label={status}
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "last_modified",
      headerName: "Last modified",
      valueFormatter: (value) => formatDate(value),
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.4,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          {params.row.status !== OnboardingCountryStatus.Onboarded && (
            <OnboardingActionsMenuCell
              params={params}
              setLoading={setLoading}
              setOnboardingCountries={setOnboardingCountries}
            />
          )}
        </div>
      ),
    },
  ];

  const distributorsTableStyles = {
    color: "#10384F",
    background: "#FFF",
    fontFamily: "Helvetica Neue",
    "& .MuiDataGrid-columnHeader, & .MuiDataGrid-scrollbarFiller": {
      backgroundColor: "#ECEFF1",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "500",
    },
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      padding: "16px 10px",
    },
  };

  function startCountryOnboarding() {
    setIsCountryOnboarding(true);
  }

  const handleRowClick = (params: any) => {
    const country = params.row;
    if (country.status === OnboardingCountryStatus.InProgress) {
      resumeOnboarding(country);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100vh - 132px)",
      }}
    >
      <DataGridPro
        sx={distributorsTableStyles}
        loading={loading}
        disableRowSelectionOnClick
        columns={columns}
        rows={onboardingCountries}
        rowHeight={52}
        slots={{
          toolbar: () => (
            <CustomDataGridToolbarWithSearch
              placeholder={textData.Onboarding.Table.SearchPlaceholder}
              buttonLabel={textData.Onboarding.Table.AddCountryBtnLabel}
              onButtonClick={startCountryOnboarding}
              fileName="countries"
            />
          ),
          exportIcon: ArrowUpwardIcon,
          pagination: CustomDatagridPagination,
        }}
        getRowId={(row) => row.country}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        onRowClick={handleRowClick}
      />
    </Box>
  );
}

export default OnboardingCountriesTable;
