import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import iconTimelines from "icons/cancel-icon/Vector.svg";
import RportPreviewTable from "./RportPreviewTable";

function PriviewModal({ reportType, open, data, onClose }: any) {
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        sx={{ "& .MuiDialog-paper": { width: '98vw', height: '98vh' } }}
      >
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <DialogTitle sx={{color: 'rgba(16, 56, 79, 1)', size: '24px'}}>{reportType} Report Template Preview</DialogTitle>
        <IconButton aria-label="delete" onClick={onClose} sx={{marginRight: '16px'}}>
        <img src={iconTimelines} alt="cancel icon" />
        </IconButton>
        </Box>
        <DialogContent>
        <RportPreviewTable data={data}/>
        </DialogContent>
      </Dialog>
    );
  }

export default PriviewModal;
