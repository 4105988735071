import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Toolbar from "customized-mui-elements/Toolbar/Toolbar";

const EmailTemplatesToolbar = () => {
  return (
    <Toolbar>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </div>
    </Toolbar>
  );
};

export default EmailTemplatesToolbar;
