import Chip from "@mui/material/Chip";
import { SxProps, Theme } from "@mui/system";

interface DefaultCellChipProps {
  label: string;
  textColor: string;
  size: "small" | "medium",
  backgroundColor: string;
  style?: SxProps<Theme>;
  ariaLabel?: string;
}

function DefaultCellChip({
  label,
  textColor,
  size,
  backgroundColor,
  style,
  ariaLabel,
}: DefaultCellChipProps) {
  return (
    <Chip
      size={size}
      label={label}
      sx={{
        color: textColor,
        backgroundColor: backgroundColor,
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
        ...style,
      }}
      aria-label={ariaLabel || label}
    />
  );
}

export default DefaultCellChip;