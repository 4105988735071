import { Box, Paper } from '@mui/material';
import { PageInfoContext } from 'contexts/PageInfoContext';
import { useStepper } from 'contexts/OnboardingContexts/StepperContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import './OnboardingPage.scss';
import OnboardingCountriesTable from 'components/OnboardingCountriesTable/OnboardingCountriesTable';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import OnboardingFooter from 'components/OnboardingFooter/OnboardingFooter';
import OnboardingSteps from 'components/OnboardingSteps/OnboardingSteps';
import { CountryType } from './types';
import InitialInformationUnsavedChangesDialog from './InitialInformationUnsavedChangesDialog';

function OnboardingPage() {
  const [error, setError] = useState<any>(null);
  const { setPageInfo } = useContext(PageInfoContext);
  const {
    activeStep,
    setActiveStep,
    isOnboardingComplete,
    initialInformationUnsavedChangesDialog,
  } = useStepper();

  const {
    isCountryOnboarding,
    setIsCountryOnboarding,
    selectedCountry,
    setSelectedCountry,
    setLoading,
    setOnboardingCountries,
  } = useOnboarding();

  const fetchOnboardingCountries = useCallback(async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_PYTHON_API}/get_countries`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response error');
      }
      const data = await response.json();
      setOnboardingCountries(data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOnboardingCountries();
  }, [fetchOnboardingCountries]);

  useEffect(() => {
    setActiveStep(0);

    return () => {
      setIsCountryOnboarding(false);
    };
  }, []);

  useEffect(() => {
    if (selectedCountry?.country) {
      setPageInfo({
        headerContent: `Onboarding ${selectedCountry.country}`,
      });
    } else {
      setPageInfo({
        headerContent: 'Onboarding',
      });
    }
  }, [selectedCountry]);

  const resumeOnboarding = (onboardingCountry: CountryType) => {
    const { country, country_code } = onboardingCountry;
    setIsCountryOnboarding(true);
    setSelectedCountry({ country, country_code });
    setActiveStep(onboardingCountry.last_step);
  };

  const isInitialInformationStep = activeStep === 0;

  if (error)
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        Failed to get onboarding countries
      </Box>
    );

  return (
    <div className="onboarding-page-container">
      <Paper
        sx={{
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        elevation={2}
      >
        {isCountryOnboarding ? (
          <>
            {initialInformationUnsavedChangesDialog.isOpen &&
              isInitialInformationStep && (
                <InitialInformationUnsavedChangesDialog
                  open={initialInformationUnsavedChangesDialog.isOpen}
                  onClose={initialInformationUnsavedChangesDialog.closeDialog}
                />
              )}
            <OnboardingSteps />
            {/* its shoud be refactored! */}
            {!isOnboardingComplete && activeStep !== 6 && (
              <OnboardingFooter activeStep={activeStep} />
            )}
          </>
        ) : (
          <OnboardingCountriesTable resumeOnboarding={resumeOnboarding} />
        )}
      </Paper>
    </div>
  );
}

export default OnboardingPage;
