import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { ReportStatus } from '../../customized-mui-elements/Statuses/Statuses';
import './DashboardDistributorsTable.scss';
import getBaseUrl from 'utils/getBaseUrl';
import { isSuccessfulStatus } from 'utils/isSuccessfulStatus';
import { generateLink } from 'utils/generateLink';

interface Row {
  distributor_id: number;
  Distributor_Name: string;
  filename: string[];
  status: string[];
}

interface MyTableProps {
  data: Row[];
  country: string;
}

const DashboardDistributorsTable: React.FC<MyTableProps> = ({
  data,
  country,
}) => {
  const fullUrl = window.location.href;
  const baseUrl = getBaseUrl(fullUrl) ?? '';

  const columnHeaderStyles = {
    fontFamily: 'inherit',
    color: 'inherit',
    fontSize: '11px',
    fontWeight: 700,
    padding: '12px',

    lineHeight: '16px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  };

  return (
    <>
      {!!data.length && (
        <TableContainer
          component={Paper}
          style={{ maxHeight: 360, overflowY: 'auto' }}
        >
          <Table
            stickyHeader
            sx={{
              fontFamily: 'Helvetica Neue',
              color: '#10384f',
              '& .MuiTableCell-root': {
                verticalAlign: 'top',
                borderBottom: 'none',
              },
              '& th.MuiTableCell-root': {
                borderBottom: '1px solid rgba(224, 224, 224, 1);',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={columnHeaderStyles}>Distributor</TableCell>
                <TableCell sx={columnHeaderStyles}>Report name</TableCell>
                <TableCell sx={columnHeaderStyles}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((report, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <span className="distributor-name">
                      {report?.Distributor_Name}
                    </span>
                    <br />
                    <span className="distributor-id">
                      {report?.distributor_id}
                    </span>
                  </TableCell>

                  <TableCell>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        listStyleType: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {report.filename.map((file, idx) => (
                        <li className="report-name list-item" key={idx}>
                          {isSuccessfulStatus(report.status[idx]) ? (
                            <a
                              href={generateLink(
                                baseUrl,
                                report.filename[idx],
                                report.distributor_id,
                                country
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {file}
                            </a>
                          ) : (
                            file
                          )}
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        listStyleType: 'none',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {report?.status.map((result, idx) => (
                        <li className="list-item" key={idx}>
                          {isSuccessfulStatus(result) ? (
                            <a
                              href={generateLink(
                                baseUrl,
                                report.filename[idx],
                                report.distributor_id,
                                country
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <ReportStatus label={result} small />
                            </a>
                          ) : (
                            <ReportStatus label={result} small />
                          )}
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default DashboardDistributorsTable;
