import useSWR from 'swr';
import fetchData from 'utils/fetchData';

export default function useAggregationPerProduct(
  country: string | null,
  authResult: any
) {
  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_aggregation_per_product`,
      { country },
    ],
    fetchData
  );
}
