import CustomDialog from "customized-mui-elements/Dialog/CustomDialog";
import { useOnboardingStepperHandlers } from "../../hooks/onboarding-hooks/useOnboardingStepperHandlers";

type Action = { text: string; handler: any };

function InitialInformationUnsavedChangesDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { saveNewCountryAndExit } = useOnboardingStepperHandlers();

  const actions: Action[] = [
    { text: "Cancel", handler: onClose },
    {
      text: "Save and exit",
      handler: () => saveNewCountryAndExit(),
    },
  ];

  return (
    <CustomDialog
      opened={open}
      title="Exit"
      actions={actions}
      onClose={onClose}
      contentText="You have unsaved changes. Are you sure you want to exit?"
    />
  );
}

export default InitialInformationUnsavedChangesDialog;
