import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useContext } from "react";
import { PageInfoContext } from "contexts/PageInfoContext";
import { useOnboarding } from "contexts/OnboardingContexts/OnboardingCountriesContext";
import { useStepper } from "contexts/OnboardingContexts/StepperContext";

function OnboardingComplete() {
  const { setIsCountryOnboarding, setSelectedCountry } = useOnboarding();
  const { setHasUnsavedChanges, setActiveStep, setIsOnboardingComplete } =
    useStepper();
  const { setPageInfo } = useContext(PageInfoContext);

  function finishOnboarding() {
    setIsCountryOnboarding(false);
    setIsOnboardingComplete(false);
    setSelectedCountry(null);
    setHasUnsavedChanges(false);
    setPageInfo({
      headerContent: "Onboarding",
    });
    setActiveStep(0);
  }

  return (
    <Box sx={{ height: "100%", display: "grid", placeItems: "center" }}>
      <div>
        <Box>
          <CheckCircleIcon
            sx={{ width: "85px", height: "85px", color: "#89D329" }}
          />
          <Box
            sx={{
              fontFamily: "Helvetica Neue",
              color: "#10384F",
              marginTop: "24px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontFamily: "inherit",
                color: "inherit",
                fontWeight: 500,
              }}
              variant="h5"
            >
              Country Onboarded!
            </Typography>
            <Typography
              sx={{
                fontFamily: "inherit",
                color: "inherit",
                marginTop: "12px",
              }}
              variant="body1"
            >
              You can change any settings at any time in the app
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{
            fontFamily: "Helvetica Neue",
            lineHeight: "24px",
            padding: "8px 16px",
            textTransform: "none",
            marginTop: "32px",
          }}
          variant="outlined"
          onClick={finishOnboarding}
        >
          Finish Onboarding
        </Button>
      </div>
    </Box>
  );
}

export default OnboardingComplete;
