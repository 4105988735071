import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Toolbar from 'customized-mui-elements/Toolbar/Toolbar';
import { Button } from '@mui/material';

const CustomToolbar = ({
  onApproveReport,
  onRejectReport,
  fileStatus,
  isReportStatusUpdated,
  isApproveDisabled,
  fileName
}: any) => {
  return (
    <Toolbar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <div>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
              fileName
            }}
          />
        </div>
        <div>
          {fileStatus === 'REVIEW' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 'auto',
                padding: '18px 16px',
              }}
            >
              <Button
                variant="outlined"
                onClick={onRejectReport}
                disabled={isReportStatusUpdated}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                onClick={onApproveReport}
                disabled={isApproveDisabled}
              >
                Approve
              </Button>
            </Box>
          )}
        </div>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;
