import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import fetchData from 'utils/fetchData';
import { AlertsContext } from 'contexts/AlertsContext';
import getTimestamp from 'utils/getTimestamp';
import {
  CountryType,
  OnboardingCountryStatus,
} from 'pages/onboarding-page/types';
import { useMsal } from '@azure/msal-react';
import { textData } from 'constants/textConstants';
import { UserDataContext } from 'App';

type ActionsMenuCellProps = {
  params: GridRenderCellParams;
  setLoading: (loading: boolean) => void;
  setOnboardingCountries: React.Dispatch<React.SetStateAction<CountryType[]>>;
};

function OnboardingActionsMenuCell({
  params,
  setLoading,
  setOnboardingCountries,
}: ActionsMenuCellProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setNewAlert } = useContext(AlertsContext);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const { authResult } = useContext(UserDataContext);
  const { accounts } = useMsal();
  const user = accounts ? accounts[0] : { name: 'default' };
  const authenticatedUserName = user.name || '';

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStatus = async (newStatus: string) => {
    if (!authenticatedUserName) return;

    const currentStatus = params.row.onboardedStatus;

    if (currentStatus === newStatus) {
      handleClose();
      return;
    }

    const oldCountryData = {
      country: params.row.country,
      country_code: params.row.country_code,
      status: params.row.status,
      contributor: params.row.contributor,
      last_step: params.row.last_step,
    };
    const newCountryData = {
      ...oldCountryData,
      country_old: oldCountryData.country,
      status: newStatus,
      contributor: authenticatedUserName,
    };

    const url = `${process.env.REACT_APP_API_PYTHON_API}/add_update_country`;
    setLoading(true);
    try {
      const response = await fetchData([
        authResult,
        'POST',
        url,
        { data: [newCountryData] },
      ]);

      if (response.post_data && response.post_data.length > 0) {
        const countryData = response.post_data[0];
        setNewAlert({
          alertType: 'success',
          text: `Successfully updated country status`,
        });
        setOnboardingCountries((prevCountries) => [
          ...prevCountries.filter(
            (prevCountry) => prevCountry.country !== countryData.country
          ),
          { ...countryData, last_modified: getTimestamp() },
        ]);
      } else {
        throw new Error(`Country status was not updated`);
      }
    } catch (error) {
      console.error('Failed to update status:', error);
      setNewAlert({
        alertType: 'error',
        text: 'Failed to update country status',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            updateStatus(OnboardingCountryStatus.InProgress);
            handleClose();
          }}
        >
          {textData.Onboarding.StatusLabels.InProgress}
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateStatus(OnboardingCountryStatus.OnHold);
            handleClose();
          }}
        >
          {textData.Onboarding.StatusLabels.OnHold}
        </MenuItem>
      </Menu>
    </>
  );
}

export default OnboardingActionsMenuCell;
