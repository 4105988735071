import { Box, Button } from '@mui/material';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { useOnboardingUI } from 'contexts/OnboardingContexts/OnboardingUIContext';
import ToggleComponent from 'customized-mui-elements/ToggleComponent/ToggleComponent';
import { useOnboardingStepperHandlers } from 'hooks/onboarding-hooks/useOnboardingStepperHandlers';
import { useContext } from 'react';
import { OnboardingTemplatesContext } from 'contexts/OnboardingContexts/OnboardingTemplatesContext';

function OnboardingFooter({ activeStep }: any) {
  const { loading } = useOnboarding();
  const { handleExitBtn, handleNavigation, handlePrevBtn, handleSaveBtn } =
    useOnboardingStepperHandlers();
  const { isDefault, isToggleDisabled, handleTriggerCount, setIsDefault } =
    useContext(OnboardingTemplatesContext);

  const {
    isNextBtnDisabled,
    isSaveBtnDisabled,
    nextBtnText,
    showPrevBtn,
    showSaveBtn,
  } = useOnboardingUI();

  const isReportsTemplateOnboarding = (step: number) => {
    return step === 4 || step === 5;
  };

  function handleTriggerClick(value: boolean) {
    setIsDefault(value);
    handleTriggerCount();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        background: 'white',
        marginTop: 'auto',
        borderTop: '1px solid #e0e0e0',
      }}
    >
      <Box>
        <Button
          sx={{
            fontFamily: 'inherit',
            color: '#10384F',
          }}
          variant="text"
          size="medium"
          disabled={loading}
          onClick={() => handleExitBtn('Initial information')}
        >
          EXIT
        </Button>
        {showSaveBtn && (
          <Button
            sx={{ color: '#0091DF', fontFamily: 'inherit' }}
            variant="text"
            size="medium"
            disabled={isSaveBtnDisabled}
            onClick={handleSaveBtn}
          >
            SAVE
          </Button>
        )}
      </Box>
      {isReportsTemplateOnboarding(activeStep) && (
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ToggleComponent
            checked={isDefault}
            isToggleDisabled={isToggleDisabled}
            label={'Default settings'}
            onChange={handleTriggerClick}
          />
        </div>
      )}
      <Box display={'flex'} gap={'8px'}>
        {showPrevBtn && (
          <Button
            sx={{
              fontFamily: 'inherit',
              borderColor: 'rgba(0, 0, 0, 0.42)',
              color: '#10384F',
            }}
            variant="outlined"
            color="inherit"
            size="medium"
            onClick={handlePrevBtn}
            disabled={loading}
          >
            PREV
          </Button>
        )}

        <Button
          sx={{ fontFamily: 'inherit' }}
          variant="contained"
          size="medium"
          onClick={handleNavigation}
          disabled={isNextBtnDisabled}
        >
          {nextBtnText}
        </Button>
      </Box>
    </Box>
  );
}

export default OnboardingFooter;
