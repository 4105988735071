import defaultFetchRequest from 'fetch/fetch-requests/defaultFetch';

export interface FetchParams {
  authResult: {
    accessToken: string;
  };
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  data?: Record<string, any>;
}

export const handleApiRequest = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  setNewAlert: (alert: any) => void,
  successMessage: string,
  errorMessage: string,
  onDataFetchSuccess?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    if (response.ok) {
      // added delay until back-end will be fixed
      setTimeout(async () => {
        await onDataFetchSuccess();
        setUpdateLoaded(false);
        setNewAlert({ alertType: 'success', text: successMessage });
      }, 3500);
    } else {
      setUpdateLoaded(false);
      setNewAlert({ alertType: 'error', text: errorMessage });
    }
  } catch (error) {
    setUpdateLoaded(false);
    setNewAlert({ alertType: 'error', text: errorMessage });
  }
};

export const handleApiRequestUpdated = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  setNewAlert: (alert: any) => void,
  successMessage: string,
  errorMessage: string,
  onDataFetchSuccess?: any,
  onErrorFetch?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    const data = await response.json(); // Parse the JSON response
    if (response.ok) {
      // added delay until back-end will be fixed
      setTimeout(async () => {
        await onDataFetchSuccess();
        setUpdateLoaded(false);
        setNewAlert({ alertType: 'success', text: successMessage });
      }, 3500);
    } else {
      setUpdateLoaded(false);
      onErrorFetch(data);
    }
  } catch (error) {
    setUpdateLoaded(false);
    // setNewAlert({ alertType: 'error', text: errorMessage });
  }
};

export const sendRequest = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  onDataFetchFailed?: any,
  onDataFetchSuccess?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    if (response.ok) {
      onDataFetchSuccess();
      return true;
    } else {
      onDataFetchFailed();
      return false;
    }
  } catch (error) {
    setUpdateLoaded(false);
    return false;
  }
};
