import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import InfoLabel from '../InfoLabel/InfoLabel';
import { DeleteOutline } from '@mui/icons-material';
import { isValidEmail } from 'utils/isValidEmail';
import DeleteItemDialog from 'components/DeleteItemDialog/DeleteItemDialog';
import { useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'utils/isValidPhoneNumber';

interface RemovableListInputProps {
  items: (string | null)[];
  handleDelete: (index: number) => void;
  handleChange: (index: number, value: string) => void;
  handleAdd: () => void;
  label: string;
  buttonText: string;
  isEmailField?: boolean;
}

function RemovableListInput({
  items,
  handleDelete,
  handleChange,
  handleAdd,
  label,
  buttonText,
  isEmailField = false,
}: RemovableListInputProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  function openDeleteItemDialog(index: number) {
    setDeleteIndex(index);
    setIsDialogOpen(true);
  }

  function closeDeleteItemDialog() {
    setIsDialogOpen(false);
  }

  function confirmDelete() {
    if (deleteIndex !== null) {
      handleDelete(deleteIndex);
    }
    closeDeleteItemDialog();
  }

  function getHelperText(
    valid: boolean,
    duplicate: boolean,
    isEmailField: boolean
  ) {
    if (!valid) {
      return `Invalid ${isEmailField ? 'email' : 'phone number'}`;
    }
    if (duplicate) {
      return `${isEmailField ? 'Email' : 'Phone number'} is duplicate`;
    }
    return null;
  }

  const itemValidationResults = useMemo(() => {
    const itemSet = new Set();
    return items.map((item) => {
      if (!item || item.trim() === '') return { valid: true, duplicate: false };

      const normalized = item.toLowerCase().trim();
      const valid = isEmailField
        ? isValidEmail(item)
        : isValidPhoneNumber(item);

      const duplicate = itemSet.has(normalized);
      itemSet.add(normalized);

      return { valid, duplicate };
    });
  }, [items, isEmailField]);

  return (
    <div className="info-container">
      <InfoLabel text={label} />
      <div className="info-inputs">
        {items.map(
          (item, index) =>
            item !== null && (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <TextField
                  required
                  type={isEmailField ? 'email' : 'tel'}
                  error={
                    !itemValidationResults[index].valid ||
                    itemValidationResults[index].duplicate
                  }
                  helperText={getHelperText(
                    itemValidationResults[index].valid,
                    itemValidationResults[index].duplicate,
                    isEmailField
                  )}
                  fullWidth
                  value={item}
                  onChange={(e) => handleChange(index, e.target.value)}
                  InputProps={{
                    style: { fontFamily: 'Helvetica Neue' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => openDeleteItemDialog(index)}
                          edge="end"
                        >
                          <DeleteOutline />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )
        )}
      </div>
      <Button
        variant="contained"
        color="inherit"
        size="small"
        onClick={handleAdd}
        sx={{
          marginTop: '16px',
          fontFamily: 'inherit',
          border: 'none',
          boxShadow: 'none',
        }}
      >
        {buttonText}
      </Button>
      <DeleteItemDialog
        opened={isDialogOpen}
        onCancel={closeDeleteItemDialog}
        onDelete={confirmDelete}
      />
    </div>
  );
}

export default RemovableListInput;
