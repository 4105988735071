import { Box, Typography } from '@mui/material';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import DataStewardsPage from 'pages/data-stewards-page/DataStewardsPage';

function OnboardingDataStewards() {
  const { selectedCountry } = useOnboarding();

  return (
    <Box sx={{ height: '100%' }}>
      <Typography
        sx={{
          textAlign: 'left',
          padding: '16px 24px',
          fontFamily: 'Helvetica Neue',
          color: '#10384F',
        }}
        variant="h6"
      >
        Users
      </Typography>
      <DataStewardsPage
        country={selectedCountry?.country}
        isOnboardingDataStewardsPage={true}
      />
    </Box>
  );
}

export default OnboardingDataStewards;
