import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CustomDatagridPagination from 'components/CustomDatagridPagination/CustomDatagridPagination';
import {
  NewColumnDataType,
  backEndColumndDataType,
} from 'types/reportTemplates';

function generateContent(
  dataType: backEndColumndDataType,
  minValue: number,
  maxValue: number
) {
  switch (dataType) {
    case 'int':
      return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
    case 'float':
      return (Math.random() * (maxValue - minValue) + minValue).toFixed(3);
    case 'date':
      return '11/08/2025';
    default:
      return 'Some text';
  }
}
function generateColumns(data: Array<NewColumnDataType>) {
  const result = data.map((obj: NewColumnDataType) => {
    return {
      field: obj.column_name,
      headerName: obj?.column_name,
      minWidth: 150,
      flex: 1,
    };
  });

  return [{ field: 'id', headerName: '#', minWidth: 50, flex: 0.2 }, ...result];
}

function generateteRows(data: Array<NewColumnDataType>, linesNumbers: number) {
  const result = [];
  const getRowObj = () => {
    const rowObj: any = {};
    data.forEach((obj: NewColumnDataType) => {
      rowObj[`${obj.column_name}`] = generateContent(
        obj.data_type,
        obj.min_value,
        obj.max_value
      );
    });
    return rowObj;
  };

  for (let i = 1; i <= linesNumbers; i++) {
    result.push({ id: i, ...getRowObj() });
  }

  return result;
}

export default function RportPreviewTable({ data }: any) {
  const columns: any = generateColumns(data);
  const rows = generateteRows(data, 10);
  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        slots={{
          pagination: CustomDatagridPagination,
        }}
        sx={{
          padding: 0,
          background: 'white',
          fontFamily: 'Helvetica Neue',
          color: '#10384F',
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-filler, & .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header.MuiDataGrid-scrollbarFiller--pinnedRight ':
            {
              backgroundColor: 'rgba(245, 245, 245, 1)',
            },
        }}
      />
    </div>
  );
}
