import React, { createContext, useContext, useMemo } from "react";
import { useOnboarding } from "./OnboardingCountriesContext";
import { useStepper } from "./StepperContext";

type OnboardingUIContextType = {
  isNextBtnDisabled: boolean;
  isSaveBtnDisabled: boolean;
  nextBtnText: string;
  showSaveBtn: boolean;
  showPrevBtn: boolean;
};

const OnboardingUIContext = createContext<OnboardingUIContextType | undefined>(
  undefined
);

export const useOnboardingUI = () => {
  const context = useContext(OnboardingUIContext);
  if (!context) {
    throw new Error(
      "useOnboardingUI must be used within a OnboardingUIProvider"
    );
  }
  return context;
};

export const OnboardingUIProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loading, selectedCountry } = useOnboarding();
  const { hasUnsavedChanges, activeStep } = useStepper();

  const isNextBtnDisabled = useMemo(
    () => loading || !selectedCountry || (selectedCountry && hasUnsavedChanges),
    [loading, selectedCountry, hasUnsavedChanges]
  );

  const isSaveBtnDisabled = useMemo(
    () =>
      !selectedCountry ||
      loading ||
      (selectedCountry !== null && !hasUnsavedChanges),
    [selectedCountry, loading, hasUnsavedChanges]
  );

  const nextBtnText = "NEXT STEP";
  const showSaveBtn = useMemo(
    () => activeStep === 0 || activeStep === 6,
    [activeStep]
  );
  const showPrevBtn = useMemo(() => activeStep > 0, [activeStep]);

  return (
    <OnboardingUIContext.Provider
      value={{
        isNextBtnDisabled,
        isSaveBtnDisabled,
        nextBtnText,
        showSaveBtn,
        showPrevBtn,
      }}
    >
      {children}
    </OnboardingUIContext.Provider>
  );
};
