import { UserDataContext } from '../App';
import isIncludePermission from '../utils/isIncludePermission';
import { useContext, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DistributorsPage from '../pages/distributors-page/DistributorsPage';
import DashboardPage from '../pages/dashboard-page/DashboardPage';
import DefaultLayout from '../layouts/default/DefaultLayout';
import ReportsListPage from '../pages/reports-page/ReportsListPage';
import ReportDetailsPage from '../pages/report-details-page/ReportDetailsPage';
import TimelinesPage from '../pages/timelines-page/TimelinesPage';
import TemplatesPage from '../pages/templates-page/TemplatesPage';
import ProductsPage from 'pages/products-page/ProductsPage';
import DataStewardsPage from 'pages/data-stewards-page/DataStewardsPage';
import OnboardingPage from 'pages/onboarding-page/OnboardingPage';
import { dontHaveAccessText } from 'constants/textConstants';

function MainPageRouts() {
  const userData: any = useContext(UserDataContext);
  const { pages } = userData.userProfile;

  return (
    <DefaultLayout userData={userData}>
      <Routes>
        <Route
          path="/reports"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              {isIncludePermission(pages, 'reports', 'read') ? (
                <ReportsListPage />
              ) : (
                <span>{dontHaveAccessText}</span>
              )}
            </Suspense>
          }
        />

        <Route
          path="/report/*"
          element={
            isIncludePermission(pages, 'reports', 'read') ? (
              <ReportDetailsPage />
            ) : (
              <span>{dontHaveAccessText}</span>
            )
          }
        />

        <Route
          path="/distributors"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              {isIncludePermission(pages, 'distributors', 'read') ? (
                <DistributorsPage />
              ) : (
                <span>{dontHaveAccessText}</span>
              )}
            </Suspense>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              {isIncludePermission(pages, 'dashboard', 'read') ? (
                <DashboardPage />
              ) : (
                <span>{dontHaveAccessText}</span>
              )}
            </Suspense>
          }
        ></Route>

        <Route
          path="/timelines"
          element={
            isIncludePermission(pages, 'timelines', 'read') ? (
              <TimelinesPage />
            ) : (
              <span>{dontHaveAccessText}</span>
            )
          }
        ></Route>

        <Route
          path="/templates"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              <>
                {isIncludePermission(pages, 'templates', 'read') ? (
                  <TemplatesPage />
                ) : (
                  <span>{dontHaveAccessText}</span>
                )}
              </>
            </Suspense>
          }
        ></Route>

        <Route
          path="/products"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              <>
                {isIncludePermission(pages, 'products', 'read') ? (
                  <ProductsPage />
                ) : (
                  <span>{dontHaveAccessText}</span>
                )}
              </>
            </Suspense>
          }
        ></Route>

        <Route
          path="/users"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              <>
                {isIncludePermission(pages, 'stewards', 'read') ? (
                  <DataStewardsPage />
                ) : (
                  <span>{dontHaveAccessText}</span>
                )}
              </>
            </Suspense>
          }
        ></Route>

        <Route
          path="/onboarding"
          element={
            <Suspense fallback={<div>Loaded</div>}>
              <div style={{ height: '100%' }}>
                {isIncludePermission(pages, 'onboarding', 'read') ? (
                  <OnboardingPage />
                ) : (
                  <span>{dontHaveAccessText}</span>
                )}
              </div>
            </Suspense>
          }
        ></Route>

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </DefaultLayout>
  );
}

export default MainPageRouts;
