import { useEffect, useState, useContext, ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const UserProfile = ({ userName }: any) => {
  const { instance, accounts } = useMsal();
  const [photoUrl, setPhotoUrl] = useState<any>(null);
  useEffect(() => {
    async function getAccessToken() {
      const request = {
        scopes: ["User.Read"], // Add appropriate scopes here
        account: accounts[0],
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (error) {
        console.error("Failed to acquire token silently", error);
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          try {
            const response = await instance.acquireTokenPopup(request);
            return response.accessToken;
          } catch (error) {
            console.error("Failed to acquire token through popup", error);
          }
        }
      }
      return null;
    }

    async function fetchPhoto() {
      const token = await getAccessToken();
      if (token) {
        const response = await fetch(
          "https://graph.microsoft.com/v1.0/me/photo/$value",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.ok) {
          const imageUrl = URL.createObjectURL(await response.blob());
          setPhotoUrl(imageUrl);
        } else {
          console.error("Failed to fetch photo");
        }
      } else {
        console.log("No token received");
      }
    }

    fetchPhoto();
  }, [instance, accounts]);

  return (
    <div>
      <div className="user-profile-dropdown">
        <div className="user-profile-dropdown__name">{userName}</div>
        {photoUrl ? (
          <img
            src={photoUrl}
            alt="User"
            className="user-profile-dropdown__ava"
          />
        ) : (
          <div className="user-profile-dropdown__ava">{userName[0]}</div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
