import { Dialog, DialogContent, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Editor from "components/Editor/Editor";
import StatusSelector from "components/StatusSelector/StatusSelector";
import PreviewIcon from "icons/preview/previewIcon.svg";
import "./EmailsTemplateTable";
import { type RichTextEditorRef } from "mui-tiptap";
import { EmailTemplateType } from "types/emailTemplatesTypes";
import BannerTextEditor from "components/EmailTemplates/BannerTextEditor";

const helperTextProps = {
  style: {
    fontFamily: "Helvetica Neue",
    fontSize: "12px",
  },
};

const EmailTemplateEditor = ({
  open,
  selectedEmailData,
  isEmea,
  isLoading,
  onClose,
  onPreview,
  onSave,
  isMonolitEmail,
}: any | EmailTemplateType) => {
  const emailBodyRef: any = useRef<RichTextEditorRef>(null);
  const emailSignatureRef = useRef<RichTextEditorRef>(null);
  const {
    notification_body: emailBody,
    signature_body: emailSignature,
    notification_title: subjectText,
    default_image_text: bannerText,
    default_image_value: base64Image,
    image_type: emailType,
  } = selectedEmailData;

  const [bannerTitleText, setBannerTitleText] = useState(bannerText);
  const [bannerSubjectText, setBannerSubjectText] = useState(subjectText);

  const [updatedBannerImage, setUpdatedBannerImage] = useState<any | string>(
    null
  );
  const [showEmailBodyError, setshowEmailBodyError] = useState(false);
  const [showEmailSignatureError, setshowEmailSignatureError] = useState(false);
  const [showBannerTitleError, setShowBannerTitleError] = useState(false);
  const [showSubjectError, setShowSubjectError] = useState(false);
  const isSaveDisabled =
    showEmailBodyError ||
    showEmailSignatureError ||
    showBannerTitleError ||
    showSubjectError;

  const [saveProcess, setSaveProcess] = useState<null | "start" | "loading">(
    null
  );

  const validateField = (
    fieldValue: string,
    setError: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!fieldValue || fieldValue.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  function updateSelectedValue(obj: EmailTemplateType) {
    obj.notification_body =
      typeof emailBodyRef?.current?.editor?.getHTML() === "string"
        ? emailBodyRef?.current.editor?.getHTML()
        : emailBody;
    obj.signature_body =
      typeof emailSignatureRef?.current?.editor?.getHTML() === "string"
        ? emailSignatureRef?.current.editor?.getHTML()
        : emailSignature;

    if (bannerSubjectText) {
      obj.notification_title = bannerSubjectText;
    }

    if (bannerTitleText) {
      obj.default_image_text = bannerTitleText;
    }

    if (updatedBannerImage) {
      obj.image_value = updatedBannerImage;
    }

    return obj;
  }

  function handlePreview() {
    const updatedEmailTemplate = updateSelectedValue(selectedEmailData);
    onPreview(updatedEmailTemplate);
  }

  useEffect(() => {
    if (saveProcess === "start") {
      if (bannerTitleText !== bannerText) {
        if (updatedBannerImage) {
          const updatedEmailTemplate = updateSelectedValue(selectedEmailData);
          updatedEmailTemplate.image_value = updatedBannerImage;
          onSave(updatedEmailTemplate);
          setSaveProcess(null);
        }
      } else {
        const updatedEmailTemplate = updateSelectedValue(selectedEmailData);
        onSave(updatedEmailTemplate);
        setSaveProcess(null);
      }
    }
  }, [saveProcess, bannerTitleText, updatedBannerImage]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      className="dialog"
      sx={{
        zIndex: 3,
        "& .MuiDialog-paper": {
          width: "880px",
          height: "calc(100vh - 113px)",
          borderRadius: "1%",
        },
      }}
    >
      <div className="dialog-header">
        <h2>Edit Notification {!isMonolitEmail && "Fragment"}</h2>
      </div>
      <BannerTextEditor
        base64ImageDefault={base64Image}
        text={bannerTitleText}
        isEditable={true}
        setUpdatedBanner={setUpdatedBannerImage}
      />
      {isLoading ? (
        "is loaded"
      ) : (
        <DialogContent
          className="dialog-content"
          sx={{ height: "calc(100vh - 113px - 64px - 64px)" }}
        >
          {" "}
          {isMonolitEmail && (
            <>
              <div className="dialog-content__email-type">
                <StatusSelector value={emailType} isEmea={isEmea} />
              </div>
              <div className="dialog-content__input">
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-required"
                  label="Banner title"
                  defaultValue={bannerText}
                  onChange={(e) => setBannerTitleText(e.target.value)}
                  onBlur={() =>
                    validateField(bannerTitleText, setShowBannerTitleError)
                  }
                  error={showBannerTitleError}
                  helperText={
                    showBannerTitleError ? "This field is required" : ""
                  }
                  FormHelperTextProps={helperTextProps}
                />
              </div>
              <div className="dialog-content__input">
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-required"
                  label="Subject"
                  defaultValue={subjectText}
                  onChange={(e) => setBannerSubjectText(e.target.value)}
                  onBlur={() =>
                    validateField(bannerSubjectText, setShowSubjectError)
                  }
                  error={showSubjectError}
                  helperText={showSubjectError ? "This field is required" : ""}
                  FormHelperTextProps={helperTextProps}
                />
              </div>
            </>
          )}
          <>
            <div
              className={
                showEmailBodyError
                  ? "editor-wrapper has-error"
                  : "editor-wrapper"
              }
            >
              <Editor
                content={emailBody}
                rteRef={emailBodyRef}
                showError={showEmailBodyError}
                setShowError={setshowEmailBodyError}
              />
            </div>
            {showEmailBodyError && (
              <span className="rte-error">This field is required</span>
            )}
          </>
          {isMonolitEmail && (
            <>
              <div
                className={
                  showEmailSignatureError
                    ? "editor-wrapper has-error"
                    : "editor-wrapper"
                }
              >
                <Editor
                  content={emailSignature}
                  rteRef={emailSignatureRef}
                  showError={showEmailSignatureError}
                  setShowError={setshowEmailSignatureError}
                />
              </div>
              {showEmailSignatureError && (
                <span className="rte-error">This field is required</span>
              )}
            </>
          )}
        </DialogContent>
      )}
      <div className="dialog-footer --email-template-editor">
        <div></div>
        <div className="footer-buttons">
          <Button
            onClick={handlePreview}
            startIcon={<img src={PreviewIcon} alt={"preview-icon"} />}
          >
            Preview
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ color: "#10384F", borderColor: "rgba(0, 0, 0, 0.42)" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setSaveProcess("start");
            }}
            variant="contained"
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EmailTemplateEditor;
