import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from 'customized-mui-elements/Toolbar/Toolbar';

const ReportsListTableToolbar = ({
  onApproveReport,
  onRejectReport,
  fileStatus,
  isReportStatusUpdated,
  isApproveDisabled,
}: any) => {
  return (
    <Toolbar>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: 'reports',
          }}
        />
      </Box>

      {fileStatus === 'REVIEW' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            ml: 'auto',
            padding: '18px 16px',
          }}
        >
          <Button
            variant="outlined"
            onClick={onRejectReport}
            disabled={isReportStatusUpdated}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            onClick={onApproveReport}
            disabled={isApproveDisabled}
          >
            Approve
          </Button>
        </Box>
      )}
    </Toolbar>
  );
};

export default ReportsListTableToolbar;
