import { sendRequest } from 'fetch/fetch-requests/handleApiRequest';

export async function handleReportRequest(
  authResult: any,
  reportName: string,
  selectedAction: string,
  handleResult: (message: string) => void,
  setUpdateLoaded: any
) {
  const baseUrl = `${process.env.REACT_APP_API_PYTHON_API}`;
  const url =
    selectedAction === 'approve'
      ? `${baseUrl}/move_file_back_to_flow?filename=${reportName}.csv`
      : `${baseUrl}/reject_file?filename=${reportName}.csv`;
  const fetchParams = {
    authResult: authResult,
    method: 'POST' as const,
    url: url,
    data: {},
  };

  const handleSuccess = () => {
    handleResult(
      selectedAction === 'approve'
        ? 'Report successfully approved'
        : 'Report successfully rejected'
    );
  };

  const handleFailure = () => {
    handleResult(
      selectedAction === 'approve'
        ? 'Report approval failed'
        : 'Report rejection failed'
    );
  };

  return sendRequest(
    fetchParams,
    setUpdateLoaded,
    handleFailure,
    handleSuccess
  );
}
