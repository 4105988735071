import "./OnboardingTimelinesNotification.scss";
import React from "react";
import NotificationPeriods from "components/NotificationSettings/NotificationPeriods/NotificationPeriods";
import NotificationRules from "components/NotificationSettings/NotificationsRules/NotificationRules";

type Period = "Daily" | "Weekly" | "Monthly" | "Quarterly" | "Custom";
const notificationPeriods: Array<Period> = ["Monthly", "Quarterly"];

const OnboardingTimelinesNotification: React.FC<any> = ({
  timelinesState,
  updateState,
}: any): JSX.Element => {
  return (
    <div className="onboarding-timelines-notification-page">
      <>
        <NotificationPeriods
          selectedPeriod={timelinesState.selectedPeriod}
          notificationPeriods={notificationPeriods}
          weeklyPeriod={timelinesState.weeklyPeriod}
          monthlyPeriod={timelinesState.monthlyPeriod}
          quarterlyPeriod={timelinesState.quarterlyPeriod}
          customPeriod={timelinesState.customPeriods}
          onPeriodChange={updateState.handlePeriodUpdate}
          onWeeklyPeriod={updateState.handleWeeklyPeriodUpdate}
          onMonthlyPeriod={updateState.handleMonthlyPeriodUpdate}
          onQuarterlyPeriod={updateState.handleQuarterlyPeriodUpdate}
          onCustomPeriod={updateState.handleCustomPeriodUpdate}
        />
        <NotificationRules
          selectedPeriod={timelinesState.selectedPeriod}
          dailyNotifications={timelinesState.dailyNotifications}
          weeklyNotifications={timelinesState.weeklyNotifications}
          monthlyNotifications={timelinesState.monthlyNotifications}
          quarterlyNotifications={timelinesState.quarterlyNotifications}
          customNotifications={timelinesState.customNotifications}
          selectedDaysState={timelinesState.selectedDaysDueDate}
          selectedFrequency={timelinesState.selectedFrequencyDueDate}
          onDailyNotificationsUpdate={
            updateState.handleDailyNotificationsUpdate
          }
          onWeeklyNotificationsUpdate={
            updateState.handleWeeklyNotificationsUpdate
          }
          onMonthlyNotifications={updateState.handleMonthlyNotificationsUpdate}
          onQuarterlyNotifications={
            updateState.handleQuarterlyNotificationsUpdate
          }
          onCustomNotifications={updateState.handleCustomNotificationsUpdate}
          onSelectedDaysState={updateState.handleSelectedDaysDueDateUpdate}
          onFrequencyChange={(e: any) =>
            updateState.handleSelectedFrequencyDueDateUpdate(e.target.value)
          }
        />
      </>
    </div>
  );
};

export default OnboardingTimelinesNotification;
