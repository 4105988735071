import React, { createContext, useState } from 'react';

export const OnboardingTemplatesContext = createContext<any>({
  isDefault: false,
  triggerCount: 0,
});

export const OnboardingTemplatesContextProvider = ({ children }: any) => {
  const [isDefault, setIsDefault] = useState(false);
  const [triggerCount, setTriggerCount] = useState(0);
  const [isToggleDisabled, setToggleDisabled] = useState(false);

  function handleTriggerCount() {
    setTriggerCount((prev) => prev + 1);
  }

  return (
    <OnboardingTemplatesContext.Provider
      value={{
        isDefault,
        isToggleDisabled,
        setIsDefault,
        triggerCount,
        handleTriggerCount,
        setTriggerCount,
        setToggleDisabled,
      }}
    >
      {children}
    </OnboardingTemplatesContext.Provider>
  );
};
