/*
convertRoleToNamingConvention('BCS.EMEA.CI.Toolbox.Data.Steward.EMEA'); // "Data Steward EMEA"
convertRoleToNamingConvention('BCS.EMEA.CI.Toolbox.Data.Steward.ZA'); // "Data Steward ZA"
*/

export function convertRole(role) {
    const dataStewardRegex = /BCS\.EMEA\.CI\.Toolbox\.Data\.Steward\.(.*)/;
    const commercialRegex = /BCS\.EMEA\.CI\.Toolbox\.Commercial\.(.*)/;

    let newRoleFormat = '';

    const dataStewardMatch = role.match(dataStewardRegex);
    const commercialMatch = role.match(commercialRegex);

    if (dataStewardMatch) {
        newRoleFormat = 'Data Steward ' + dataStewardMatch[1];
    } else if (commercialMatch) {
        newRoleFormat = 'Commercial Persona ' + commercialMatch[1];
    }

    return newRoleFormat;
}

export function convertBack(newRole) {
    const dataStewardRegex = /Data Steward (.*)/;
    const commercialRegex = /Commercial Persona (.*)/;

    let originalRoleFormat = '';

    const dataStewardMatch = newRole.match(dataStewardRegex);
    const commercialMatch = newRole.match(commercialRegex);

    if (dataStewardMatch) {
        originalRoleFormat = `BCS.EMEA.CI.Toolbox.Data.Steward.${dataStewardMatch[1]}`;
    } else if (commercialMatch) {
        originalRoleFormat = `BCS.EMEA.CI.Toolbox.Commercial.${commercialMatch[1]}`;
    }

    return originalRoleFormat;
}
