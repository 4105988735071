import styled from "@emotion/styled";
import { Box, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface CustomTooltipProps extends TooltipProps {
  width?: string;
}

export const LightTooltip = styled(
  ({ className, width = "400px", ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: `0px 3px 5px -1px #00000033, 
                0px 6px 10px 0px #00000024, 
                0px 1px 18px 0px #0000001F`,
    minWidth: width,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
}));

export const tooltipContentStyles = {
  "& div": {
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Helvetica Neue",
    color: "#10384F",
    "& p": {
      fontSize: "10px",
      margin: 0,
      lineHeight: "14px",
      fontWeight: 500,
    },
    "& p:first-child": {
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: 400,
    },
  },
};

export const TooltipContent = () => {
  return (
    <Box sx={tooltipContentStyles}>
      <div>
        <p>Number Data Type</p>
        <p>Numeric values without decimal points, e.g. 375</p>
      </div>
      <div>
        <p>Decimal Data Type</p>
        <p>Numeric values with decimal points, e.g. 3.75</p>
      </div>
      <div>
        <p>Date Data Type</p>
        <p>Calendar dates in dd/mm/yyyy format, e.g. 13/01/2024</p>
      </div>
      <div>
        <p>Text Data Type</p>
        <p>
          Alphanumeric characters, including letters, numbers, and symbols, e.g.
          for Field 'Invoice Number' the value can be UA-24-17-030/0001-21​33
        </p>
      </div>
    </Box>
  );
};
