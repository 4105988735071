import OnboardingDistributors from "components/OnboardingDistributors/OnboardingDistributors";
import OnboardingNotificationEmails from "components/OnboardingNotificationEmails/OnboardingNotificationEmails";
import OnboardingProductsPage from "components/OnboardingProductsPage/OnboardingProductsPage";
import OnboardingReports from "components/OnboardingReports/OnboardingReports";
import OnboardingTimelines from "components/OnboardingTimelines/OnboardingTimelines";
import OnboardingDataStewards from "components/OnboardingUsersPage/OnboardingDataStewards";
import { useStepper } from "contexts/OnboardingContexts/StepperContext";
import InitialInformation from "pages/onboarding-page/InitialInformation";
import OnboardingComplete from "pages/onboarding-page/OnboardingComplete";

function OnboardingSteps() {
  const { activeStep } = useStepper();

  switch (activeStep) {
    case 0:
      return <InitialInformation />;
    case 1:
      return <OnboardingDataStewards />;
    case 2:
      return <OnboardingDistributors />;
    case 3:
      return <OnboardingProductsPage />;
    case 4:
      return (
        <OnboardingReports
          reportTitle="Inventory Report"
          reportType="Inventory"
        />
      );
    case 5:
      return (
        <OnboardingReports reportTitle="Sell-out Report" reportType="Sellout" />
      );
    case 6:
      return <OnboardingTimelines />;
    case 7:
      return <OnboardingNotificationEmails />;
    case 8:
      return <OnboardingComplete />;
    default:
      return <div>Unknown Step</div>;
  }
}

export default OnboardingSteps;
