import useDialogControls from 'hooks/useDialogControls';
import React, { createContext, useState, useContext } from 'react';
type Step = {
  label: string;
};

interface StepperContextType {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  handleReset: () => void;
  steps: Step[];
  maxStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  isOnboardingComplete: boolean;
  setIsOnboardingComplete: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  initialInformationUnsavedChangesDialog: {
    isOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
  };
  onboardingTimelinesUnsavedChangesDialog: {
    isOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
  };
}

const StepperContext = createContext<StepperContextType>({
  activeStep: 0,
  handleNext: () => {},
  handleBack: () => {},
  handleReset: () => {},
  setActiveStep: () => {},
  steps: [],
  maxStep: 0,
  isOnboardingComplete: false,
  setIsOnboardingComplete: () => {},
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  initialInformationUnsavedChangesDialog: {
    isOpen: false,
    openDialog: () => {},
    closeDialog: () => {},
  },
  onboardingTimelinesUnsavedChangesDialog: {
    isOpen: false,
    openDialog: () => {},
    closeDialog: () => {},
  },
});

export const useStepper = () => useContext(StepperContext);

export const StepperProvider = ({ children }: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const initialInformationUnsavedChangesDialog = useDialogControls();
  const onboardingTimelinesUnsavedChangesDialog = useDialogControls();

  const steps = [
    {
      label: 'Initial information',
    },
    {
      label: 'Users',
    },
    {
      label: 'Distributors',
    },
    {
      label: 'Products',
    },
    {
      label: 'Inventory Report',
    },
    {
      label: 'Sell-out Report',
    },
    {
      label: 'Timelines',
    },
    {
      label: 'Notification Emails',
    },
  ];

  const maxStep = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        handleNext,
        handleBack,
        handleReset,
        isOnboardingComplete,
        setIsOnboardingComplete,
        steps,
        maxStep,
        setActiveStep,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        initialInformationUnsavedChangesDialog,
        onboardingTimelinesUnsavedChangesDialog,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
