import './Dashboard.scss';
import { Card } from 'components/Card/Card';
import { Link } from 'react-router-dom';
import PieChart from 'components/Charts/Chart';
import { useReportsData } from '../../fetch/fetch-hooks/country-dashboard-hooks/useReports';
import { useContext } from 'react';
import { getFromLocalStorage } from '../../services/storageInterection';
import CircularProgress from '@mui/material/CircularProgress';
import { UserDataContext } from '../../App';
import DashboardDistributorsTable from './DashboardDistributorsTable';
import DashboardReportsTable from './DashboardReportsTable';
import { getPastThreeMonthsInterval } from 'utils/getPastThreeMonthsInterval';
import {
  fullyReportedDistr,
  fullyNotReportedDistr,
  partiallyReportedDistr,
} from 'utils/dashboardUtils';
import { isReportInTimeInterval } from 'utils/isReportInTimeInterval';
import useDistributorsDetails from 'fetch/fetch-hooks/distributors-hooks/useDistributorsDetails';
import { DistributorType } from 'components/DistributorsTable/types';

interface FileDetailsWithStatus {
  distributor_id: number;
  Distributor_Name: string;
  status: string;
  widget_status: string;
  filename: string;
  country: string;
  reporting_period: string;
}

interface FileDetailsWithStatusArray {
  distributor_id: number;
  Distributor_Name: string;
  status: string[];
  widget_status: string[];
  filename: string[];
  country: string;
}

type DistributorData = (FileDetailsWithStatus | FileDetailsWithStatusArray)[];

function Dashboard() {
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const userData = useContext(UserDataContext);
  const { userProfile, authResult } = userData;
  const { data: activeDistributorsData, isLoading } = useDistributorsDetails(
    selectedCountry,
    authResult
  );
  const activeDistributorsIds =
    activeDistributorsData?.data?.reduce(
      (accumulator: number[], distributor: DistributorType) => {
        if (distributor.active === 1) {
          accumulator.push(distributor.distributor_id);
        }
        return accumulator;
      },
      []
    ) || [];

  const { data: realReportsData, isLoading: isReportsDataLoading } =
    useReportsData(
      [
        'GET',
        `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status`,
        { country: selectedCountry },
      ],
      authResult
    );

  const { data: realDistributorData, isLoading: isDistributorDataLoading } =
    useReportsData(
      [
        'GET',
        `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status_array`,
        { country: selectedCountry },
      ],
      authResult
    );

  const filterActiveDistributors = (data: any) => {
    if (!data) return;
    return data.filter((item: any) =>
      activeDistributorsIds.includes(item.distributor_id)
    );
  };

  const activeReportsData = filterActiveDistributors(realReportsData?.data);
  const distributorDataWithStatus = filterActiveDistributors(
    realDistributorData?.data
  );

  function handleDistributorssDataForChart(distributorsData: any) {
    return [
      {
        type: 'Reported',
        typeNumber: fullyReportedDistr(distributorsData),
        total: distributorsData.length,
      },
      {
        type: 'Missing',
        typeNumber: fullyNotReportedDistr(distributorsData),
        total: distributorsData.length,
      },
      {
        type: 'Partially Reported',
        typeNumber: partiallyReportedDistr(distributorsData),
        total: distributorsData.length,
      },
    ];
  }

  function handleReportsDataForChart(reportData: any) {
    return [
      {
        type: 'Received',
        typeNumber: reportData.filter(
          (obj: any) => obj.status !== 'MISSING' && obj.status !== 'REWORK'
        ).length,
        total: reportData?.length,
      },
      {
        type: 'Missing',
        typeNumber: reportData.filter(
          (obj: any) => obj.status === 'MISSING' || obj.status === 'REWORK'
        ).length,
        total: reportData?.length,
      },
    ];
  }

  const filterData = (array: any, selectedCountry: string) => {
    return array.filter((obj: any) => obj?.country === selectedCountry);
  };

  const filterDataForTables = (
    array: DistributorData,
    selectedCountry: string
  ): DistributorData => {
    const interval = getPastThreeMonthsInterval();

    return array
      .filter((distributor) => distributor.country === selectedCountry)
      .map((distributor) => {
        if (typeof distributor.filename === 'string') {
          return isReportInTimeInterval(distributor.filename, interval)
            ? distributor
            : null;
        }

        const reportsInTimeInterval = distributor.filename
          .map((filename, index) =>
            isReportInTimeInterval(filename, interval) ? index : -1
          )
          .filter((index) => index !== -1);

        if (reportsInTimeInterval.length === 0) {
          return null;
        }

        return {
          ...distributor,
          filename: reportsInTimeInterval.map(
            (index) => distributor.filename[index]
          ),
          status: reportsInTimeInterval.map(
            (index) => distributor.status[index]
          ),
          widget_status: reportsInTimeInterval.map(
            (index) => distributor.widget_status[index]
          ),
        };
      })
      .filter(
        (
          distributor
        ): distributor is FileDetailsWithStatus | FileDetailsWithStatusArray =>
          distributor !== null
      );
  };

  return (
    <>
      {!userProfile?.isEMEA &&
        (isReportsDataLoading || isDistributorDataLoading) && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '45%',
              left: '45%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}

      {activeReportsData?.length > 0 &&
        distributorDataWithStatus?.length > 0 &&
        !userProfile?.isEMEA && (
          <>
            <div className="inform-cards">
              <div className="inform-cards__inform-card">
                <Card
                  name="Reports"
                  bodyInfo={`${
                    activeReportsData?.filter(
                      (obj: any) =>
                        obj.status !== 'MISSING' && obj.status !== 'REWORK'
                    ).length
                  }/${activeReportsData?.length}`}
                  bodyExplaining={'received/total'}
                  status={''}
                  update={''}
                >
                  {' '}
                  <Link to={'/reports'} relative="path">
                    See all
                  </Link>
                </Card>
              </div>
              <div className="inform-cards__inform-card">
                <Card
                  name="Exceptions Found"
                  bodyInfo={`${
                    filterData(activeReportsData, selectedCountry).filter(
                      (obj: any) => obj.status === 'REVIEW'
                    ).length
                  }`}
                  bodyExplaining={'reports to review'}
                  status={''}
                  isDangerStatus
                >
                  {' '}
                  <Link to={'/reports'} relative="path"></Link>
                </Card>
              </div>
              <div className="inform-cards__inform-card">
                <Card
                  name="Mapped Successfully"
                  bodyInfo={`${
                    filterData(activeReportsData, selectedCountry).filter(
                      (obj: any) => obj.status === 'SUCCESS'
                    ).length
                  }`}
                  bodyExplaining={''}
                  status={'.'}
                >
                  {' '}
                  <Link to={'/reports'} relative="path"></Link>
                </Card>
              </div>
            </div>

            <div className="panel">
              <div className="panel__chart">
                <PieChart
                  name="Distributors"
                  data={handleDistributorssDataForChart(
                    filterData(distributorDataWithStatus, selectedCountry)
                  )}
                  width={200}
                  height={200}
                />
              </div>
              <div className="panel__table">
                <DashboardDistributorsTable
                  data={
                    filterDataForTables(
                      distributorDataWithStatus,
                      selectedCountry
                    ) as FileDetailsWithStatusArray[]
                  }
                  country={selectedCountry}
                />
              </div>
            </div>
            <div className="panel">
              <div className="panel__chart">
                <PieChart
                  name="Reports"
                  data={handleReportsDataForChart(activeReportsData)}
                  width={200}
                  height={200}
                />
              </div>

              <div className="panel__table">
                <DashboardReportsTable
                  data={
                    filterDataForTables(
                      activeReportsData,
                      selectedCountry
                    ) as FileDetailsWithStatus[]
                  }
                  country={selectedCountry}
                />
              </div>
            </div>
          </>
        )}
    </>
  );
}

export default Dashboard;
