import ProductsTable from 'components/ProductsTable/ProductsTable';
import { useContext, useEffect, useMemo } from 'react';
import { PageInfoContext } from 'contexts/PageInfoContext';
import { getFromLocalStorage } from 'services/storageInterection';
import useProductsData from 'fetch/fetch-hooks/products-hooks/useProductsData';
import { Box, CircularProgress } from '@mui/material';
import { UserDataContext } from 'App';

export type ProductRowData = Product & { idx: number };

export type Product = {
  country_code: string;
  country_name: string;
  material_number: number;
  material_name: string;
  row_insert_timestamp: string;
  row_update_timestamp: string;
  active: ProductActiveStatus;
  row_number: number;
};

export type ProductWithOldProperties = Product & {
  country_code_old: string;
  country_name_old: string;
  material_number_old: number;
  material_name_old: string;
  row_insert_timestamp_old: string;
  row_update_timestamp_old: string;
  active_old: ProductActiveStatus;
};

export type ProductActiveStatus = 1 | 0;

function ProductsPage({
  country,
  tableHeight = 'calc(100vh - 132px)',
  isOnboardingProductsPage = false,
}: {
  country?: string;
  tableHeight?: string;
  isOnboardingProductsPage?: boolean;
}) {
  const { setPageInfo } = useContext(PageInfoContext);
  const { authResult } = useContext(UserDataContext);
  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const { data, isLoading } = useProductsData(selectedCountry, authResult);
  const productsData = data?.data;

  useEffect(() => {
    if (!isOnboardingProductsPage) {
      setPageInfo({
        headerContent: 'Products',
      });
    }
  }, [isOnboardingProductsPage, setPageInfo]);

  function getProductsRowData(data: Product[]): ProductRowData[] {
    return data.map((product, index) => ({
      idx: index + 1,
      ...product,
    }));
  }

  const productsRowData = useMemo(() => {
    if (Array.isArray(productsData)) {
      return getProductsRowData(productsData);
    }
    return [];
  }, [productsData]);

  const containerStyles = (isOnboarding: boolean) => ({
    background: 'white',
    border: isOnboarding ? 'none' : '1px solid #e0e0e0',
    borderRadius: isOnboarding ? '0px' : '4px',
    overflow: 'hidden',
  });

  return (
    <Box sx={containerStyles(isOnboardingProductsPage)}>
      {isLoading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <ProductsTable
          authResult={authResult}
          products={productsData?.length > 0 ? productsRowData : []}
          tableHeight={tableHeight}
        />
      )}
    </Box>
  );
}

export default ProductsPage;
