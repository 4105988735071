import './TemplatesPage.scss';
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { getFromLocalStorage } from 'services/storageInterection';
import useFetchReportTemplateData from 'fetch/fetch-hooks/template-hooks/useFetchReportTemplateData';
import { useSWREmailsTemplatesMix } from 'fetch/fetch-hooks/template-hooks/useSWREmailsTemplates';
import ReportTemplateTable from 'components/ReportTemplateTable/ReportTemplateTable';
import EmailsTemplateTable from 'components/EmailTemplates/EmailsTemplateTable';
import {
  FetchParams,
  handleApiRequest,
  handleApiRequestUpdated,
} from 'fetch/fetch-requests/handleApiRequest';
import { AlertsContext } from 'contexts/AlertsContext';
import formatDataForBackEnd from 'utils/formatDataForBackEnd';
import { EmailTemplateType } from 'types/emailTemplatesTypes';
import { ReportTemplateDataType } from 'types/reportTemplates';
import TemplatesControlPanel from './TemplatesControlPanel';
import { PageInfoContext } from 'contexts/PageInfoContext';
import useEnabledReports from 'fetch/fetch-hooks/useEnabledReports';
import CircularProgress from '@mui/material/CircularProgress';
import { UserDataContext } from 'App';

type EnabledReportsType = {
  inventory: boolean;
  sellout: boolean;
};

function useData(selectedCountry: any, authResult: any) {
  const [emailsTemplatesData, setEmailsTemplatesData] =
    useState<any>(undefined);

  const {
    data: emailsMonolitData,
    isLoading: isLoadingEmailsMonolitData,
    mutate: mutateMonolitEmails,
  } = useSWREmailsTemplatesMix(
    true,
    selectedCountry,
    authResult,
    'get_monolit_email_templates'
  );

  const {
    data: emailsCompositData,
    isLoading: isLoadingemailsCompositData,
    mutate: mutateCompositEmails,
  } = useSWREmailsTemplatesMix(
    true,
    selectedCountry,
    authResult,
    'get_composite_email_templates'
  );

  useEffect(() => {
    if (emailsMonolitData && emailsCompositData) {
      setEmailsTemplatesData([
        ...emailsMonolitData?.data,
        ...emailsCompositData?.data,
      ]);
    }
  }, [emailsMonolitData, emailsCompositData]);

  return {
    isLoadingemailsCompositData,
    isLoadingEmailsMonolitData,
    emailsTemplatesData,
    mutateMonolitEmails,
    mutateCompositEmails,
  };
}

function TemplatesPage() {
  const { setPageInfo } = useContext(PageInfoContext);
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const templeteType: any = {
    0: 'Inventory',
    1: 'Sellout',
    2: 'emails',
  };

  const [isTableDataLoaded, setReportUpdateInProgress] = useState(true);
  const { authResult } = useContext(UserDataContext);
  const {
    data: enabledReports,
    isLoading: isEnabledReportsLoading,
  }: { data: EnabledReportsType; isLoading: boolean } = useEnabledReports(
    authResult,
    selectedCountry
  );
  const [templateNumber, setTemplateNumber] = useState<any>(null);

  useEffect(()=>{
    if(enabledReports){
      const {inventory, sellout} = enabledReports;
      if(!inventory && sellout){
      setTemplateNumber(1)
      } else if(!sellout && inventory){
        setTemplateNumber(0)
      } else if(inventory && sellout){
        setTemplateNumber(0)
      }
        else {
          setTemplateNumber(2)
        }
    }
  }, [enabledReports])

  const { data: reportsTemplatesData, mutate: mutatereportsTemplatesData } =
    useFetchReportTemplateData(
      typeof templateNumber === 'number'? templeteType[templateNumber] : null,
      selectedCountry,
      authResult
    );
  const {
    isLoadingemailsCompositData,
    isLoadingEmailsMonolitData,
    emailsTemplatesData,
    mutateMonolitEmails,
    mutateCompositEmails,
  } = useData(selectedCountry, authResult);
  const [templatesData, setTemplatesData] = useState<
    Array<ReportTemplateDataType>
  >([]);
  const { setNewAlert } = useContext(AlertsContext);
  const [emailsUpdateInProgress, setEmailsUpdateInProgress] = useState(false);

  useEffect(() => {
    setPageInfo({
      headerContent: 'Templates',
    });
  }, []);

  useEffect(() => {
    if (
      !!reportsTemplatesData?.data &&
      (templeteType[templateNumber] === 'Inventory' ||
        templeteType[templateNumber] === 'Sellout')
    ) {
      setTemplatesData(
        reportsTemplatesData?.data
          .slice()
          .sort((obj: any, obj2: any) => obj?.row_order - obj2.row_order)
      );
      setReportUpdateInProgress(false);
    }
  }, [reportsTemplatesData]);

  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTemplateNumber(newValue);
  };

  function getLabelsArray(enabledReports: EnabledReportsType) {
    const labels = [];
    if (enabledReports?.inventory) {
      labels.push({
        label: 'Inventory report',
        number: 0,
      });
    }
    if (enabledReports?.sellout) {
      labels.push({
        label: 'Sell-out report',
        number: 1,
      });
    }
    labels.push({
      label: 'Emails Notifications',
      number: 2,
    });
    return labels;
  }

  async function handleSaveUpdateEmailTemplate(data: any | EmailTemplateType) {
    const objectBeforChanges = formatDataForBackEnd(
      emailsTemplatesData.find(
        (obj: EmailTemplateType) =>
          obj.notification_name === data.notification_name
      )
    );
    for (let key in data) {
      objectBeforChanges[key] = data[key];
    }
    const isMonolitEmail = data.notification_type.includes('monolit');

    async function mutateEmailsTemplatesData() {
      const monolitEmails = await mutateMonolitEmails();
      const compositEmails = await mutateCompositEmails();
      return { monolitEmails, compositEmails };
    }

    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${
        isMonolitEmail
          ? 'update_monolit_email_templates'
          : 'update_composite_email_templates'
      }`,
      data: { data: [objectBeforChanges] },
    };

    await handleApiRequest(
      fetchParams,
      setEmailsUpdateInProgress,
      setNewAlert,
      'Email template was updated successfully',
      'Something went wrong, please try again',
      mutateEmailsTemplatesData
    );
  }

  async function handleDeleteReportColumn(data: any) {
    const url = `delete_report_column?country=${data.country}&column_name=${data.column_name}&translated_column_name=${data.translated_column_name}&report_type=${data.report_type}&report_subtype=default&row_order=${data.row_order}`;
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${url}`,
    };

    await handleApiRequest(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'Column was deleted',
      'Something went wrong, please try again',
      mutatereportsTemplatesData
    );
  }

  async function handleReportColumnUpdate(updatedValueArr: Array<any>) {
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/update_report_templates`,
      data: { data: updatedValueArr },
    };

    await handleApiRequest(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'Report template was updated successfully',
      'Something went wrong, please try again',
      mutatereportsTemplatesData
    );
  }

  async function handleAddNewReportColumn(
    newColumn: any,
    clearInputsFn: () => void
  ) {
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/add_new_report_column`,
      data: newColumn,
    };

    const onSuccess = () => {
      mutatereportsTemplatesData();
      clearInputsFn();
    };

    await handleApiRequestUpdated(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'New column was added',
      'Something went wrong, please try again',
      onSuccess,
      (responceData: any) => {
        if (responceData.error) {
          console.log(responceData.error);
          setNewAlert({ alertType: 'error', text: responceData.error });
        } else {
          setNewAlert({
            alertType: 'error',
            text: 'Something went wrong, please try again',
          });
        }
      }
    );
  }

  return (
    <div className="templates-page page">
      {!!enabledReports && (
        <div className="templates-table-wrapper">
          <div className="template-control-panel">
            <TemplatesControlPanel
              value={templateNumber}
              onChange={handleTabChange}
              labels={getLabelsArray(enabledReports)}
            />
          </div>

          <div className="inventory-template-table">
            {templeteType[templateNumber] !== 'emails' && (
              <ReportTemplateTable
                isTableDataLoaded={isTableDataLoaded || isEnabledReportsLoading}
                data={templatesData}
                onRowUpdate={handleReportColumnUpdate}
                onAddNewRow={handleAddNewReportColumn}
                onDelete={handleDeleteReportColumn}
                reportType={templeteType[templateNumber]}
                isDisabledMode={false}
              />
            )}
            {templateNumber == 2 && (
              <EmailsTemplateTable
                data={emailsTemplatesData}
                isLoading={
                  emailsUpdateInProgress ||
                  isLoadingemailsCompositData ||
                  isLoadingEmailsMonolitData ||
                  isEnabledReportsLoading
                }
                onSaveUpdateEmailTemplate={handleSaveUpdateEmailTemplate}
              />
            )}
          </div>
          <div className="sellout-template-report-table"></div>
          <div className="email-template"></div>
        </div>
      )}

      {isEnabledReportsLoading ? (
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
    </div>
  );
}

export default TemplatesPage;
