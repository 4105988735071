export const textData = {
  Notifications: {
    Inputs: {
      StartDay: "Start Day",
      DueDay: "Due Day",
      NextPeriod: "Day of the Next Period",
    },
    Title: {
      ReportingPeriod: "Reporting period",
      NotificationRules: "Notification Rules",
      BeforeReportingStartDay: "Before reporting Start Day",
      BeforeReportingDueDay: "Before reporting Due Day",
      AfterReportingDueDay: "After reporting Due Day",
    },
  },
  Onboarding: {
    StatusLabels: {
      InProgress: "In Progress",
      OnHold: "On Hold",
    },
    Table: {
      SearchPlaceholder: "Start typing...",
      AddCountryBtnLabel: "ADD COUNTRY",
    },
  },
};

export const dontHaveAccessText = `Sorry, you don't have access to this page. Please ask your administrator for access.`;
