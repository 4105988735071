import useSWR from 'swr';
import fetchData from 'utils/fetchData';

function useFetchReportTemplateData(
  reportType: 'Inventory' | 'Sellout' | null,
  country: string | null,
  authResult: any
) {
  return useSWR(
    [
      authResult,
      'GET',
      reportType
        ? `${process.env.REACT_APP_API_PYTHON_API}/get_report_templates?country=${country}&report_type=${reportType}&report_subtype=default`
        : null,
    ],
    fetchData,
    {
      // revalidateOnFocus: false,
      // refreshInterval: 10000000
    }
  );
}

export default useFetchReportTemplateData;
