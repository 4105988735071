import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

const Toolbar = ({ children }: any) => {
  return (
    <GridToolbarContainer
      sx={{
        minHeight: "58px",
        display: "flex",
        alignItems: "center",
        padding: "5px 10px",
        width: '100%'
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          width: 300,
          marginRight: "15px",
          "& .MuiOutlinedInput-input": {
            fontFamily: "Helvetica Neue",
            fontSize: "16px",
            lineHeight: "24px",
          },
          "& .MuiSvgIcon-root": {
            display: "none",
          },
        }}
        label="Search"
        size="small"
        variant="outlined"
        placeholder={"Search"}
      />
      {children}
    </GridToolbarContainer>
  );
};

export default Toolbar;
