import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedPages from "./ProtectedPages";
import MainPageRouts from "./MainPageRouts";

function Router({ status }: any) {
  return (
    <>
      {status ? (
        <BrowserRouter>
          <Routes>
            <Route
              path="*"
              element={
                <ProtectedPages status={status}>
                  <MainPageRouts/>
                </ProtectedPages>
              }
            ></Route>
          </Routes>
        </BrowserRouter>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Router;
