function getKeyByValue(object, value) {
    // Search for the key in the object using the value
    for (const [key, val] of Object.entries(object)) {
        if (val === value) {
            return key; // Return the key if the value matches
        }
    }
    return null; // Return null if no matching key is found
}

export default getKeyByValue;