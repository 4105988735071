import ActionCellTemplates from "./ActionCellTemplates";
import "./EmailsTemplateTable";
import React from "react";
import { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EmailTemplateEditor from "components/EmailTemplates/EmailTemplateEditor";
import EmailPreviewWindow from "components/EmailTemplates/EmailPreviewWindow";
import { EmailTemplateType } from "types/emailTemplatesTypes";
import CustomDatagridPagination from "components/CustomDatagridPagination/CustomDatagridPagination";
import DefaultCellChip from "components/DefaultCellChip/DefaultCellChip";
import {
  successColor,
  errorColor,
  infoColor,
  attentionColor,
} from "constants/emailTemplatesConstants";
import EmailTemplatesToolbar from "./EmailTemplatesToolbar";
interface EmailsTemplateTableProps {
  data: Array<EmailTemplateType>;
  isLoading: boolean;
  onSaveUpdateEmailTemplate: any;
  tableHeight?: string;
}

function EmailsTemplateTable({
  data,
  isLoading,
  onSaveUpdateEmailTemplate,
  tableHeight = "calc(100vh - 180px)",
}: EmailsTemplateTableProps) {
  const [choosedEmail, setChoosedEmail] = useState<EmailTemplateType | null>(
    null
  );
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [headerMessageFragment, setHeaderMessageFragment] = useState<
    string | null
  >(null);
  const [footerMessageFragment, setFooterMessageFragment] = useState<
    string | null
  >(null);
  const columns = [
    {
      field: "#",
      headerName: "#",
      filterable: false,
      renderCell: (params: any) =>
        params.api.getAllRowIds().indexOf(params.id) + 1,
      flex: 0.02,
    },
    {
      field: "app_name",
      headerName: "Name",
      filterable: false,

      flex: 0.45,
    },
    {
      field: "notification_type",
      headerName: "Type",
      filterable: false,
      flex: 0.45,
    },

    {
      field: "notification_title",
      headerName: "Title",
      flex: 0.45,
    },
    {
      field: "default_image_text",
      headerName: "Banner",
      flex: 0.45,
      renderCell: (params: any) => (
        <>
          {params.row.image_type === "error" && (
            <DefaultCellChip
              label={params?.row?.default_image_text}
              textColor={"white"}
              backgroundColor={errorColor}
              size="small"
            />
          )}
          {params.row.image_type === "success" && (
            <DefaultCellChip
              label={params?.row?.default_image_text}
              textColor={"white"}
              backgroundColor={successColor}
              size="small"
            />
          )}
          {params.row.image_type === "info" && (
            <DefaultCellChip
              label={params?.row?.default_image_text}
              textColor={"white"}
              backgroundColor={infoColor}
              size="small"
            />
          )}
          {params.row.image_type === "attention" && (
            <DefaultCellChip
              label={params?.row?.default_image_text}
              textColor={"white"}
              backgroundColor={attentionColor}
              size="small"
            />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      filterable: false,
      renderCell: (params: any) => (
        <div>
          <ActionCellTemplates
            ActionsCellTemplates
            data={params}
            onActionClick={(event: React.MouseEvent) =>
              console.log(event, params)
            }
          />
        </div>
      ),
      flex: 0.05,
    },
  ];

  useEffect(() => {
    if (data) {
      const headerMessageFragmentObj = data.find(
        (obj: EmailTemplateType) =>
          obj.notification_type === "composite_error_message_header"
      );
      const footerMessageFragmentObj = data.find(
        (obj: EmailTemplateType) =>
          obj.notification_type === "composite_error_message_footer"
      );
      headerMessageFragmentObj &&
        setHeaderMessageFragment(headerMessageFragmentObj.notification_body);
      footerMessageFragmentObj &&
        setFooterMessageFragment(footerMessageFragmentObj.notification_body);
    }
  }, [data]);

  function closePrewiewWindow() {
    setPreviewMode(false);
    setChoosedEmail(null);
  }

  function closeEditWindow() {
    setEditMode(false);
    setChoosedEmail(null);
  }

  function openEditWindow() {
    setPreviewMode(false);
    setEditMode(true);
  }

  function handleRowClick(clickedRow: any, e: any) {
    setChoosedEmail(null);
    setTimeout(() => {
      setChoosedEmail({ ...clickedRow?.row });
    }, 0);
    setPreviewMode(true);
  }

  function handleSave(data: EmailTemplateType) {
    onSaveUpdateEmailTemplate(data);
    setEditMode(false);
    setChoosedEmail(null);
  }

  function openPreviewWindow(obj: EmailTemplateType) {
    setEditMode(false);
    setPreviewMode(true);
    if (obj) {
      setChoosedEmail(obj);
    }
  }

  return (
    <>
      <EmailPreviewWindow
        open={!!choosedEmail && isPreviewMode}
        onClose={closePrewiewWindow}
        emailBodyContent={choosedEmail?.notification_body}
        emailSignatureContent={choosedEmail?.signature_body}
        onBackToEdit={openEditWindow}
        base64Image={choosedEmail?.default_image_value}
        bannerText={choosedEmail?.default_image_text}
        headerFragment={headerMessageFragment}
        footerFragment={footerMessageFragment}
        type={choosedEmail?.notification_type}
      />

      {isEditMode && !!choosedEmail && (
        <EmailTemplateEditor
          open={isEditMode && !!choosedEmail}
          isEmea={false}
          selectedEmailData={choosedEmail}
          isLoading={false}
          onClose={closeEditWindow}
          onPreview={openPreviewWindow}
          onSave={handleSave}
          isMonolitEmail={choosedEmail?.notification_type.includes("monolit")}
        />
      )}

      <DataGridPro
        hideFooterSelectedRowCount
        columns={columns}
        rows={!!data ? data : []}
        getRowId={(row) => row.notification_name}
        loading={isLoading}
        onRowClick={handleRowClick}
        slots={{
          toolbar: EmailTemplatesToolbar,
          pagination: (props) => (
            <CustomDatagridPagination
              {...props}
              pageSizes={[10, 25, 50, 100]}
            />
          ),
        }}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        sx={{
          height: tableHeight,
          background: "white",
          borderTop: "none",
          overflowY: "scroll",
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          fontFamily: "Helvetica Neue",
          color: "#10384F",
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller": {
            backgroundColor: "rgba(245, 245, 245, 1)",
          },

          "& .super-app.success": {
            backgroundColor: "rgba(102, 181, 18, 1)",
          },
          "& .super-app.error": {
            backgroundColor: "rgba(224, 87, 129, 1)",
          },
          "& .super-app.info": {
            backgroundColor: "rgba(53, 160, 254, 1)",
          },
          "& .super-app.attention": {
            backgroundColor: "rgba(223, 147, 0, 1)",
          },
        }}
      />
    </>
  );
}

export default EmailsTemplateTable;
