import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import PreviewIcon from "icons/preview/previewIcon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Toolbar from "customized-mui-elements/Toolbar/Toolbar";
import { LightTooltip } from "./TemplateModals/Tooltip";
import { Box, Typography } from "@mui/material";

const tooltipContentStyles = {
  width: "200px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  fontFamily: "Helvetica Neue",
  fontSize: "10px",
  fontWeight: 500,
  lineHeight: "14px",
  color: "#10384f",
  "& p": {
    margin: 0,
  },
};

function TooltipContent() {
  return (
    <Box sx={tooltipContentStyles}>
      <div>
        <p>Example for September 2023 for the Distributor with ID=12345678:</p>
      </div>
      <div>
        <p>InventoryReport_12345678_09_2023.csv</p>
        <p>SellOutReport_12345678_09_2023.csv</p>
      </div>
    </Box>
  );
}

const ReportTempleteTableToolbar = ({
  onAddNewRow,
  onPreview,
  isDisabledMode,
  isOnboardingReportTemplate,
}: any) => {
  return (
    <Toolbar>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "auto",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <div>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
          <Button
            onClick={onPreview}
            startIcon={<img src={PreviewIcon} alt={"preview-icon"} />}
          >
            Preview
          </Button>
        </div>
        <div className="toolbar-preview-section">
          <div className="toolbar-preview-section__naming-convention naming-convention">
            <LightTooltip title={<TooltipContent />} width="200px" arrow>
              <InfoOutlinedIcon />
            </LightTooltip>
            <Typography
              sx={{
                marginLeft: "8px",
                color: "#10384F",
                fontFamily: "Helvetica Neue",
                fontWeight: 400,
                textTransform: "none",
              }}
            >
              File Naming Convention
            </Typography>
          </div>
          <Button
            variant={isOnboardingReportTemplate ? "outlined" : "contained"}
            onClick={onAddNewRow}
            disabled={isDisabledMode}
            sx={{
              marginRight: '20px',
              fontFamily: "Helvetica Neue",
              ...(isOnboardingReportTemplate && {
                borderColor: "rgba(0, 0, 0, 0.42)",
                color: "#10384F",
              }),
            }}
          >
            ADD REPORT FIELD
          </Button>
        </div>
      </div>
    </Toolbar>
  );
};

export default ReportTempleteTableToolbar;
