import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { protectedResources } from 'authConfig';

const useAuthFetchWithMsal = (msalRequest: any = null) => {
  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      scopes: protectedResources.apiTodoList.scopes.read,
      redirectUri: '/redirect',
    }
  );
  return { msalError, result };
};

export default useAuthFetchWithMsal;
