import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function ToggleComponent({ checked, label, onChange, isToggleDisabled }: any) {
  return (
      <FormGroup sx={{ width: "180px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              disabled={isToggleDisabled}
            />
          }
          label={label}
        />
      </FormGroup>
  );
}