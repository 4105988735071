import { useState, useEffect, useContext } from 'react';
import useFetchReportTemplateData from 'fetch/fetch-hooks/template-hooks/useFetchReportTemplateData';
import ReportTemplateTable from 'components/ReportTemplateTable/ReportTemplateTable';
import {
  FetchParams,
  handleApiRequest,
  handleApiRequestUpdated,
} from 'fetch/fetch-requests/handleApiRequest';
import { AlertsContext } from 'contexts/AlertsContext';
import { ReportTemplateDataType } from 'types/reportTemplates';
import { OnboardingTemplatesContext } from 'contexts/OnboardingContexts/OnboardingTemplatesContext';
import {
  getFromLocalStorage,
  saveToLocalStorage,
  removeSpecificFromLocalStorage,
} from 'services/storageInterection';
import { UserDataContext } from 'App';

type ReportsTemplateOnboardingProps = {
  reportType: 'Inventory' | 'Sellout';
  country?: string;
  tableHeight?: string;
  isDisabledMode: boolean;
  setIsReportTypeActive?: React.Dispatch<React.SetStateAction<boolean>>;
};

function ReportsTemplateOnboarding({
  country,
  reportType,
  tableHeight = 'calc(100vh - 180px)',
  isDisabledMode,
  setIsReportTypeActive,
}: ReportsTemplateOnboardingProps) {
  const { setNewAlert } = useContext(AlertsContext);
  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const [templatesData, setTemplatesData] = useState<
    Array<ReportTemplateDataType>
  >([]);
  const [isTableDataLoaded, setReportUpdateInProgress] = useState(true);
  const { authResult } = useContext(UserDataContext);
  const { data: reportsTemplatesData } = useFetchReportTemplateData(
    reportType,
    selectedCountry,
    authResult
  );
  const {
    isDefault,
    setIsDefault,
    triggerCount,
    setTriggerCount,
    setToggleDisabled,
  } = useContext(OnboardingTemplatesContext);

  useEffect(() => {
    if (
      !!reportsTemplatesData?.data &&
      (reportType === 'Inventory' || reportType === 'Sellout')
    ) {
      const firstItem = reportsTemplatesData.data[0];
      if (firstItem && 'active' in firstItem && setIsReportTypeActive) {
        setIsReportTypeActive(!!firstItem?.active);
      }
      setTemplatesData(
        reportsTemplatesData?.data
          .slice()
          .sort((obj: any, obj2: any) => obj?.row_order - obj2.row_order)
      );
      setReportUpdateInProgress(false);
    }
  }, [reportsTemplatesData]);

  // temporary solution, until normal endpoints will be realized on back-end side
  function removeBatch(arr: any, fn: any) {
    async function handleDeleteReportColumn2(data: any) {
      const url = `delete_report_column?country=${country}&column_name=${data.column_name}&translated_column_name=${data.translated_column_name}&report_type=${data.report_type}&report_subtype=default&row_order=${data.row_order}`;
      const fetchParams: FetchParams = {
        authResult,
        method: 'POST',
        url: `${process.env.REACT_APP_API_PYTHON_API}/${url}`,
      };
      await handleApiRequest(
        fetchParams,
        setReportUpdateInProgress,
        (x: any) => console.log(x),
        'Column was deleted',
        'Something went wrong, please try again',
        () => console.log('is removed')
      );
    }

    const arrForRemoving: any = [];
    arr.forEach((element: any) => {
      arrForRemoving.push(handleDeleteReportColumn2(element));
    });
    Promise.all(arrForRemoving)
      .then(() => {
        console.log('All requests completed successfully');
        fn();
      })
      .catch((error) => {
        console.error('An error occurred with one of the requests:', error);
      });
  }

  function returnBatch(arr: any, fn: any) {
    async function handleAddNewReportColumn2(newRow: any) {
      const fetchParams: FetchParams = {
        authResult,
        method: 'POST',
        url: `${process.env.REACT_APP_API_PYTHON_API}/add_new_report_column`,
        data: { ...newRow, country },
      };
      await handleApiRequest(
        fetchParams,
        setReportUpdateInProgress,
        () => {},
        'New column was added',
        'Something went wrong, please try again',
        () => console.log('final')
      );
    }

    const arrForRemoving: any = [];
    arr.forEach((element: any) => {
      arrForRemoving.push(
        handleAddNewReportColumn2({ ...element, remove_special_chars: false })
      );
    });
    Promise.all(arrForRemoving)
      .then(() => {
        console.log('All requests completed successfully');
        fn();
      })
      .catch((error) => {
        console.error('An error occurred with one of the requests:', error);
      });
  }
  // temporary solution, until normal endpoints will be realized on back-end side

  useEffect(() => {
    if (templatesData && !triggerCount) {
      // This will be true if all items are EDF
      const isOnlyEDFColumns = templatesData.every(
        (obj: ReportTemplateDataType) => obj.isEDF === true
      );
      if (isOnlyEDFColumns) {
        setIsDefault(true);
        const storedData = getFromLocalStorage(
          `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`
        );
        if (!storedData) {
          setToggleDisabled(true);
        } else setToggleDisabled(false);
      } else {
        setIsDefault(false);
        setToggleDisabled(false);
      }
    }
  }, [templatesData, reportsTemplatesData]);

  useEffect(() => {
    if (triggerCount > 0) {
      if (isDefault) {
        const notEDFcolumns = templatesData.filter(
          (obj: ReportTemplateDataType) => obj.isEDF === false
        );
        if (notEDFcolumns) {
          saveToLocalStorage(
            `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`,
            templatesData
          );
          removeBatch(notEDFcolumns, () =>
            setTemplatesData((prev) =>
              prev.filter((obj: any) => obj.isEDF === true)
            )
          );
          setTriggerCount(0);
        }
      } else {
        const storedData = getFromLocalStorage(
          `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`
        );
        const notEDFcolumns = storedData.filter(
          (obj: ReportTemplateDataType) => obj.isEDF === false
        );
        returnBatch(notEDFcolumns, () => setTemplatesData([...storedData]));
        setTriggerCount(0);
      }
    }
  }, [triggerCount]);

  async function handleDeleteReportColumn(data: any) {
    const url = `delete_report_column?country=${selectedCountry}&column_name=${data.column_name}&translated_column_name=${data.translated_column_name}&report_type=${data.report_type}&report_subtype=default&row_order=${data.row_order}`;
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${url}`,
    };

    const updatedData = templatesData.reduce(
      (
        acc: ReportTemplateDataType[],
        current: ReportTemplateDataType,
        index
      ) => {
        if (current.translated_column_name !== data.translated_column_name) {
          if (current.row_order > data.row_order) {
            acc.push({
              ...current,
              row_order: index, // index here relates to the total processed so far, not original index
            });
          } else
            acc.push({
              ...current,
            });
        }
        return acc;
      },
      []
    );

    await handleApiRequest(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'Column was deleted',
      'Something went wrong, please try again',
      () => {
        setTemplatesData(updatedData);
        removeSpecificFromLocalStorage(
          `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`
        );
      }
    );
  }

  async function handleReportColumnUpdate(updatedValueArr: Array<any>) {
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/update_report_templates`,
      data: { data: updatedValueArr },
    };
    const updatedData = templatesData.map((obj: ReportTemplateDataType) => {
      if (
        obj.translated_column_name !== updatedValueArr[0].translated_column_name
      ) {
        return obj;
      } else {
        return updatedValueArr[0];
      }
    });

    await handleApiRequest(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'Report template was updated successfully',
      'Something went wrong, please try again',
      () => {
        setTemplatesData(updatedData);
        removeSpecificFromLocalStorage(
          `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`
        );
      }
    );
  }

  async function handleAddNewReportColumn(
    newRow: any,
    clearInputsFn: () => void
  ) {
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/add_new_report_column`,
      data: newRow,
    };
    const updatedData = [...templatesData];
    updatedData.push(newRow);

    const onSuccess = () => {
      setTemplatesData(updatedData);
      removeSpecificFromLocalStorage(
        `onboardingReportTemplateDataCSCI${selectedCountry}-${reportType}`
      );
      clearInputsFn();
    };

    await handleApiRequestUpdated(
      fetchParams,
      setReportUpdateInProgress,
      setNewAlert,
      'New column was added',
      'Something went wrong, please try again',
      onSuccess,
      (responceData: any) => {
        if (responceData.error) {
          console.log(responceData.error);
          setNewAlert({ alertType: 'error', text: responceData.error });
        } else {
          setNewAlert({
            alertType: 'error',
            text: 'Something went wrong, please try again',
          });
        }
      }
    );
  }

  return (
    <div>
      <ReportTemplateTable
        isTableDataLoaded={isTableDataLoaded}
        data={templatesData}
        onRowUpdate={handleReportColumnUpdate}
        onAddNewRow={handleAddNewReportColumn}
        onDelete={handleDeleteReportColumn}
        reportType={reportType}
        tableHeight={tableHeight}
        isDisabledMode={isDisabledMode}
        isOnboardingReportTemplate={true}
        country={selectedCountry}
      />
    </div>
  );
}

export default ReportsTemplateOnboarding;
