import './Sidebar.scss';
import { Link } from 'react-router-dom';
import LogoIcon from '../../icons/logo/Logos.svg';
import iconDashboard from '../../icons/dashboard/NavPieChartLine.svg';
import iconDistributors from '../../icons/distributors/NavBuildingLine.svg';
import iconReports from '../../icons/reports/NavFile3Line.svg';
import iconTimelines from '../../icons/timelines/NavNotificationLine.svg';
import iconTemplates from '../../icons/templates/NavFileSettingsLine.svg';
import iconOnboarding from '../../icons/onboarding/_Icon_.svg';
import iconStewards from '../../icons/stewards/NavGroupLine.svg';
import iconProducts from '../../icons/products/_Icon_.svg';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../services/storageInterection';
import { useContext } from 'react';
import { UserDataContext } from '../../App';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import isIncludePermission from '../../utils/isIncludePermission';
import { Divider } from '@mui/material';
import CountryOnboardingStepper from 'components/CountryOnboardingStepper/CountryOnboardingStepper';
import { useDistributorToShow } from 'contexts/DistributorDetailsContext';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
// TODO: This is a temporary solution until a fix on the back-end side is implemented.
import useSWR from 'swr';

const fetcher = ({ url, accessToken }: any) =>
  fetch(url, {
    headers: {
      Authorization: accessToken,
    },
  }).then((res) => res.json());

 function useOnboardingCountriesData(auth: any) {
  return useSWR(
    {
      url: `${process.env.REACT_APP_API_PYTHON_API}/get_countries`,
      accessToken: auth?.accessToken,
    },
    fetcher
  );
}

const styles = {
  select: {
    width: '192px',
    height: '40px',
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
};

function SidebarComponent({ onPageChoose, activePage }: any) {
  const currentCountry = getFromLocalStorage('selectedCountry');
  const userData: any = useContext(UserDataContext);
  const { pages, isEMEA, countries } = userData.userProfile;
  const { setDistributorToShowId } = useDistributorToShow();
  const { isCountryOnboarding } = useOnboarding();
  // TODO: This is a temporary solution until a fix on the back-end side is implemented.
  const { authResult } = useContext(UserDataContext);
  const { data: onboardedCountries } = useOnboardingCountriesData(authResult);
// TODO: This is a temporary solution until a fix on the back-end side is implemented.

  function chooseCountry(country: string) {
    saveToLocalStorage('selectedCountry', country);
    window.location.reload();
  }

  const isActive = (tabName: string) => {
    return tabName === activePage;
  };
  return (
    <div className="sidebar">
      <div className="sidebar__logo-section logo-section">
        <img src={LogoIcon} alt="logo"></img>
        <div className="logo-section__text">
          <span className="logo-section__app-name">Bayer CISO ToolBox</span>
          <span className="logo-section__app-type">
            {isEMEA ? 'EMEA App' : `${countries[0]}`}
          </span>
        </div>
      </div>
      {!isCountryOnboarding && isEMEA && (
        <div className="sidebar__selector">
          <div>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="appSelectorLabel">Country</InputLabel>
              <Select
                labelId="appSelectorLabel"
                id="appSelector"
                value={currentCountry}
                onChange={(e) => chooseCountry(e.target.value)}
                label="Country data"
                sx={styles.select}
              >
                {!!onboardedCountries
                  ? onboardedCountries.filter((obj: any)=> obj.status === 'Onboarded')
                      .sort((current: any, next: any) => current.country.localeCompare(next.country))
                      .map(({country}: any) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))
                  : null}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      {isCountryOnboarding ? (
        <CountryOnboardingStepper />
      ) : (
        <>
          <div className="sidebar__navigation navigation">
            <>
              {isIncludePermission(pages, 'dashboard', 'read') && (
                <div
                  className={`board-name
                      ${
                        isActive('dashboard')
                          ? '--active-board'
                          : '--inactive-board'
                      }`}
                >
                  <img className="icon" src={iconDashboard} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('dashboard')}
                    className="nav-link"
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </div>
              )}
            </>
            <>
              {isIncludePermission(pages, 'reports', 'read') && (
                <div
                  className={`board-name
            ${
              isActive('reports') || isActive('report')
                ? '--active-board'
                : '--inactive-board'
            }`}
                >
                  <img className="icon" src={iconReports} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('reports')}
                    className="nav-link"
                    to="/reports"
                  >
                    <span>Reports</span>
                  </Link>
                </div>
              )}
            </>
            <>
              {isIncludePermission(pages, 'distributors', 'read') && (
                <div
                  className={`board-name
          ${isActive('distributors') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconDistributors} alt="icon" />
                  <Link
                    onClick={() => {
                      setDistributorToShowId(null);
                      onPageChoose('distributors');
                    }}
                    className="nav-link"
                    to="/distributors"
                  >
                    Distributors
                  </Link>
                </div>
              )}
            </>

            <>
              {isIncludePermission(pages, 'stewards', 'read') && (
                <div
                  className={`board-name
            ${isActive('users') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconStewards} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('stewards')}
                    className="nav-link"
                    to="/users"
                  >
                    Users
                  </Link>
                </div>
              )}
            </>

            <>
              {isIncludePermission(pages, 'products', 'read') && (
                <div
                  className={`board-name
            ${isActive('products') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconProducts} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('products')}
                    className="nav-link"
                    to="/products"
                  >
                    Products
                  </Link>
                </div>
              )}
            </>

            <>
              {isIncludePermission(pages, 'timelines', 'read') && (
                <div
                  className={`board-name
          ${isActive('timelines') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconTimelines} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('timelines')}
                    className="nav-link"
                    to="/timelines"
                  >
                    Timelines
                  </Link>
                </div>
              )}
            </>

            <>
              {isIncludePermission(pages, 'templates', 'read') && (
                <div
                  className={`board-name
            ${isActive('templates') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconTemplates} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('templates')}
                    className="nav-link"
                    to="/templates"
                  >
                    Templates
                  </Link>
                </div>
              )}
            </>
          </div>
          {isEMEA && <Divider />}
          <div className="sidebar__onboarding">
            <>
              {isIncludePermission(pages, 'onboarding', 'read') && (
                <div
                  className={`board-name
          ${isActive('onboarding') ? '--active-board' : '--inactive-board'}`}
                >
                  <img className="icon" src={iconOnboarding} alt="icon" />
                  <Link
                    onClick={() => onPageChoose('onboarding')}
                    className="nav-link"
                    to="/onboarding"
                  >
                    Onboarding
                  </Link>
                </div>
              )}
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default SidebarComponent;
