import { useState, useEffect, useContext } from 'react';
import { getFromLocalStorage } from 'services/storageInterection';
import { useSWREmailsTemplatesMix } from 'fetch/fetch-hooks/template-hooks/useSWREmailsTemplates';
import EmailsTemplateTable from 'components/EmailTemplates/EmailsTemplateTable';
import {
  FetchParams,
  handleApiRequest,
} from 'fetch/fetch-requests/handleApiRequest';
import { AlertsContext } from 'contexts/AlertsContext';
import formatDataForBackEnd from 'utils/formatDataForBackEnd';
import { EmailTemplateType } from 'types/emailTemplatesTypes';
import { UserDataContext } from 'App';

function useData(selectedCountry: any, authResult: any) {
  const [emailsTemplatesData, setEmailsTemplatesData] =
    useState<any>(undefined);

  const {
    data: emailsMonolitData,
    isLoading: isLoadingEmailsMonolitData,
    mutate: mutateMonolitEmails,
  } = useSWREmailsTemplatesMix(
    true,
    selectedCountry,
    authResult,
    'get_monolit_email_templates'
  );

  const {
    data: emailsCompositData,
    isLoading: isLoadingemailsCompositData,
    mutate: mutateCompositEmails,
  } = useSWREmailsTemplatesMix(
    true,
    selectedCountry,
    authResult,
    'get_composite_email_templates'
  );
  useEffect(() => {
    if (emailsMonolitData && emailsCompositData) {
      setEmailsTemplatesData([
        ...emailsMonolitData?.data,
        ...emailsCompositData?.data,
      ]);
    }
  }, [emailsMonolitData, emailsCompositData]);

  return {
    isLoadingemailsCompositData,
    isLoadingEmailsMonolitData,
    emailsTemplatesData,
    mutateMonolitEmails,
    mutateCompositEmails,
  };
}

function EmailsOnboardingPage({ country }: { country?: string }) {
  const { setNewAlert } = useContext(AlertsContext);
  const { authResult } = useContext(UserDataContext);
  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const [emailsUpdateInProgress, setEmailsUpdateInProgress] = useState(false);

  const {
    isLoadingemailsCompositData,
    isLoadingEmailsMonolitData,
    emailsTemplatesData,
    mutateMonolitEmails,
    mutateCompositEmails,
  } = useData(selectedCountry, authResult);

  const handleSaveUpdateEmailTemplate = async (
    data: any | EmailTemplateType
  ) => {
    const objectBeforChanges = formatDataForBackEnd(
      emailsTemplatesData.find(
        (obj: EmailTemplateType) =>
          obj.notification_name === data.notification_name
      )
    );
    for (let key in data) {
      objectBeforChanges[key] = data[key];
    }
    const isMonolitEmail = data.notification_type.includes('monolit');

    async function mutateEmailsTemplatesData() {
      const monolitEmails = await mutateMonolitEmails();
      const compositEmails = await mutateCompositEmails();
      return { monolitEmails, compositEmails };
    }

    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${
        isMonolitEmail
          ? 'update_monolit_email_templates'
          : 'update_composite_email_templates'
      }`,
      data: { data: [objectBeforChanges] },
    };

    await handleApiRequest(
      fetchParams,
      setEmailsUpdateInProgress,
      setNewAlert,
      'Email template was updated successfully',
      'Something went wrong, please try again',
      mutateEmailsTemplatesData
    );
  };

  const tableHeight = 'calc(100vh - 132px - 68.5px - 64px)';

  return (
    <div>
      <EmailsTemplateTable
        data={emailsTemplatesData}
        isLoading={
          emailsUpdateInProgress ||
          isLoadingemailsCompositData ||
          isLoadingEmailsMonolitData
        }
        onSaveUpdateEmailTemplate={handleSaveUpdateEmailTemplate}
        tableHeight={tableHeight}
      />
    </div>
  );
}

export default EmailsOnboardingPage;
