import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { ReportStatus } from '../../customized-mui-elements/Statuses/Statuses';
import getBaseUrl from 'utils/getBaseUrl';
import { isSuccessfulStatus } from 'utils/isSuccessfulStatus';
import { generateLink } from 'utils/generateLink';

export interface Row {
  distributor_id: number;
  Distributor_Name: string;
  status: string;
  widget_status: string;
  filename: string;
}

interface MyTableProps {
  data: Row[];
  country: string;
}

const DashboardReportsTable: React.FC<MyTableProps> = ({ data, country }) => {
  const fullUrl = window.location.href;
  const baseUrl = getBaseUrl(fullUrl) ?? '';

  const columnHeaderStyles = {
    fontFamily: 'inherit',
    color: 'inherit',
    fontSize: '11px',
    fontWeight: 700,
    padding: '12px',

    lineHeight: '16px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  };

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 360, overflowY: 'auto' }}
    >
      <Table
        stickyHeader
        sx={{
          fontFamily: 'Helvetica Neue',
          color: '#10384f',
          '& .MuiTableCell-root': {
            verticalAlign: 'top',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={columnHeaderStyles}>Distributor</TableCell>
            <TableCell sx={columnHeaderStyles}>Report name</TableCell>
            <TableCell sx={columnHeaderStyles}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((report, index) => (
            <TableRow key={index}>
              <TableCell>
                {isSuccessfulStatus(report.status) ? (
                  <a
                    href={generateLink(
                      baseUrl,
                      report.filename,
                      report.distributor_id,
                      country
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <>
                      <span className="distributor-name">
                        {report?.Distributor_Name}
                      </span>
                      <br />
                      <span className="distributor-id">
                        {report?.distributor_id}
                      </span>
                    </>
                  </a>
                ) : (
                  <>
                    <span className="distributor-name">
                      {report?.Distributor_Name}
                    </span>
                    <br />
                    <span className="distributor-id">
                      {report?.distributor_id}
                    </span>
                  </>
                )}
              </TableCell>

              <TableCell
                sx={{
                  fontFamily: 'Helvetica Neue',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  textAlign: 'left',
                  color: '#10384f',
                }}
              >
                {isSuccessfulStatus(report.status) ? (
                  <a
                    href={generateLink(
                      baseUrl,
                      report.filename,
                      report.distributor_id,
                      country
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {report.filename}
                  </a>
                ) : (
                  report.filename
                )}
              </TableCell>
              <TableCell>
                {isSuccessfulStatus(report.status) ? (
                  <a
                    href={generateLink(
                      baseUrl,
                      report.filename,
                      report.distributor_id,
                      country
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ReportStatus label={report?.status} small />
                  </a>
                ) : (
                  <ReportStatus label={report?.status} small />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardReportsTable;
