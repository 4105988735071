import "./DaySelector.scss";
const DaySelector: React.FC<any> = ({
  selectedDays,
  onUpdate,
}): JSX.Element => {
  const structure = [
    { value: "monday", id: 0, name: "M", editable: true },
    { value: "tuesday", id: 1, name: "T", editable: true },
    { value: "wednesday", id: 2, name: "W", editable: true },
    { value: "thursday", id: 3, name: "T", editable: true },
    { value: "friday", id: 4, name: "F", editable: true },
    { value: "saturday", id: 5, name: "S", editable: false },
    { value: "sunday", id: 6, name: "S", editable: false },
  ];

  function isSelected(id: number) {
    return selectedDays.includes(id);
  }

  function handleClick(id: number) {
    if (selectedDays.includes(id)) {
      const newState = selectedDays.filter(
        (dayNumber: number) => dayNumber !== id
      );
      onUpdate({ afterReportingDueDate: newState });
    } else if (id !== 5 && id !== 6) {
      onUpdate({ afterReportingDueDate: [...selectedDays, id] });
    }
  }

  return (
    <div className="day-selector">
      {structure.map(({ value, id, name, editable }) => (
        <div
          className={`day-selector__day ${
            isSelected(id) ? "--selected-day" : ""
          }`}
          onClick={() => handleClick(id)}
          key={id}
        >
          {name}
        </div>
      ))}
    </div>
  );
};

export default DaySelector;
