import "./Header.scss";
import { useEffect, useState, useContext, ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { PageInfoContext } from "../../contexts/PageInfoContext";
import ProfilePopUp from "components/Header/ProfilePopUp";
import { saveToLocalStorage } from "services/storageInterection";
import { UserDataContext } from "../../App";
import UserProfile from "./UserProfile";

function Header() {
  const userData: any = useContext(UserDataContext);
  const { selectedRole, userRoles } = userData;
  const { accounts, instance } = useMsal();
  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error));
  };
  const user = accounts ? accounts[0] : { name: "default" };
  console.log(accounts, instance, "accounts, instance");
  const { pageInfo } = useContext(PageInfoContext);
  const [headerContent, setHeaderContent] = useState<string>();
  const [buttonToRender, setButtonToRender] = useState<ReactNode>();

  console.log(pageInfo, "pageInfo");

  useEffect(() => {
    if (pageInfo?.headerContent) {
      setHeaderContent(pageInfo?.headerContent);
    }
    if (pageInfo?.button) {
      setButtonToRender(pageInfo?.button);
    } else {
      setButtonToRender(null);
    }
  }, [pageInfo]);

  function handleRoleChange(newRole: string) {
    saveToLocalStorage("selectedUserRoleToolBox", newRole);
    window.location.reload();
  }

  return (
    <div className="header">
      <h1 className="page-name">
        {buttonToRender}
        {headerContent}
      </h1>
      <div className="user-profile-tab">
        <ProfilePopUp
          userRoles={userRoles}
          selectedRole={selectedRole}
          onLogout={handleLogoutRedirect}
          onRoleChange={handleRoleChange}
          userName={<UserProfile userName={user?.name} />}
        />
      </div>
    </div>
  );
}

export default Header;
