import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ArrowDropDownIcon } from '@mui/x-date-pickers-pro';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { CountrySuggestion } from './types';
import { useStepper } from 'contexts/OnboardingContexts/StepperContext';
import { UserDataContext } from 'App';
import useCountrySuggestions from 'fetch/fetch-hooks/onboarding-hooks/useCountrySuggestions';

function InitialInformation() {
  const { authResult } = useContext(UserDataContext);
  const { selectedCountry, setSelectedCountry, loading } = useOnboarding();
  const { hasUnsavedChanges, setHasUnsavedChanges } = useStepper();
  const [connectionStatus, setConnectionStatus] = useState('notConnected');
  const { data: suggestedCountries, isLoading: isLoadingSuggestedCountries } =
    useCountrySuggestions(authResult);

  const bayerIdentityNowUrl = 'https://bayer.identitynow.com/ui/d/mysailpoint';

  const SelectIcon = () =>
    isLoadingSuggestedCountries ? (
      <CircularProgress
        size={20}
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
      />
    ) : (
      <ArrowDropDownIcon className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon" />
    );

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 68.5px)',
        fontFamily: 'Helvetica Neue',
        '& .MuiInputBase-input, & .MuiInputLabel-root, & .MuiInputBase-input::placeholder':
          {
            fontFamily: 'Helvetica Neue',
          },
      }}
    >
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <>
          <Box sx={{ padding: '24px 24px 32px' }}>
            <Typography
              sx={{ fontFamily: 'inherit' }}
              variant="h5"
              align="left"
            >
              Location
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                textAlign: 'left',
                marginTop: '24px',
              }}
            >
              <FormControl sx={{ width: '320px' }}>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  IconComponent={SelectIcon}
                  value={selectedCountry?.country || ''}
                  label="Location"
                  onChange={(e) => {
                    setHasUnsavedChanges(true);
                    const selected = suggestedCountries.find(
                      (country: CountrySuggestion) =>
                        country.country === e.target.value
                    );
                    setSelectedCountry(selected || null);
                  }}
                  disabled={
                    isLoadingSuggestedCountries ||
                    (selectedCountry !== null && !hasUnsavedChanges)
                  }
                >
                  {suggestedCountries && suggestedCountries.length > 0 ? (
                    suggestedCountries.map(
                      (suggestedCountry: CountrySuggestion, idx: number) => (
                        <MenuItem
                          sx={{ fontFamily: 'Helvetica Neue' }}
                          key={idx}
                          value={suggestedCountry.country}
                        >
                          {suggestedCountry.country}
                        </MenuItem>
                      )
                    )
                  ) : (
                    <MenuItem disabled> No Country Available </MenuItem>
                  )}
                  {selectedCountry?.country && (
                    <MenuItem
                      sx={{ fontFamily: 'Helvetica Neue' }}
                      value={selectedCountry.country}
                    >
                      {selectedCountry.country}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                sx={{ width: '320px' }}
                disabled
                label="ISO 2 code"
                value={selectedCountry ? selectedCountry.country_code : ''}
              />
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {/* Hidden from UI until fully functional */}
      {/* <Box sx={{ padding: "24px 24px 32px" }}>
        <Typography variant="h5" align="left" fontFamily={"inherit"}>
          Bayer IdentityNow/MS ENTRA connection
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            textAlign: "left",
            marginTop: "24px",
            flexDirection: "column",
            "& p": {
              margin: 0,
            },
          }}
        >
          <FormControl sx={{ width: "320px" }}>
            <InputLabel>Country</InputLabel>
            <Select
              label="Status"
              value={connectionStatus}
              onChange={(event) => setConnectionStatus(event.target.value)}
            >
              <MenuItem
                sx={{ fontFamily: "Helvetica Neue" }}
                value={"notConnected"}
              >
                Not Connected
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: "Helvetica Neue" }}
                value={"connected"}
              >
                Connected
              </MenuItem>
            </Select>
          </FormControl>
          <p>
            Please folow the{" "}
            <Link href={bayerIdentityNowUrl} target="_blank">
              link
            </Link>{" "}
            to make a setting
          </p>
        </Box>
      </Box> */}
    </Box>
  );
}

export default InitialInformation;
