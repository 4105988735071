import { Box, Typography } from "@mui/material";
import { useOnboarding } from "contexts/OnboardingContexts/OnboardingCountriesContext";
import EmailsOnboardingPage from "pages/emails-onboarding-page/EmailsOnboardingPage";

function OnboardingNotificationEmails() {
  const { selectedCountry } = useOnboarding();
  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        sx={{
          textAlign: "left",
          padding: "16px 24px",
          fontFamily: "Helvetica Neue",
          color: "#10384F",
        }}
        variant="h6"
      >
        Notification Emails
      </Typography>
      <EmailsOnboardingPage country={selectedCountry?.country} />
    </Box>
  );
}

export default OnboardingNotificationEmails;
