import useSWR from 'swr';
import fetchData from '../../../utils/fetchData';

function useFetchReportsData(country: string | null, authResult: any) {
  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status_all`,
      { country },
    ],
    fetchData,
    {
      // revalidateOnFocus: false,
      // refreshInterval: 10000000
    }
  );
}

export default useFetchReportsData;
