export type CountrySuggestion = {
  country: string;
  country_code: string;
};

export enum OnboardingCountryStatus {
  InProgress = "In Progress",
  OnHold = "On Hold",
  Onboarded = "Onboarded",
}

export type CountryType = {
  country: string;
  country_code: string;
  contributor: string;
  status: OnboardingCountryStatus;
  last_modified: string;
  last_step: number;
};
