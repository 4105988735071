import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import './ReportDetails.scss';
import MultiSelectorSnackBar from 'components/MultiSelectorSnackBar/MultiSelectorSnackBar';
import MappingAlternativesCell from 'components/MappingAlternatives/MappingAlternativesCell';
import { findSameProducts } from './services';
import {
  ReportStatusType,
  ReportDetailsData,
  ProductDetailsType,
  ReportDetailsProps,
  SameProductsType,
  SelectedOptionType,
  ApprovePayloadObject,
} from 'types/reportDetailsTypes';
import CustomToolbar from './CustomToolbar';
import CustomDatagridPagination from 'components/CustomDatagridPagination/CustomDatagridPagination';
import {
  FetchParams,
  handleApiRequest,
} from 'fetch/fetch-requests/handleApiRequest';
import useAuthFetchWithMsal from 'fetch/auth-hooks/authHook';
import { protectedResources } from 'authConfig';
import { AlertsContext } from 'contexts/AlertsContext';
import DeleteItemDialog from 'components/DeleteItemDialog/DeleteItemDialog';

const ReportDetails: React.FC<ReportDetailsProps> = ({
  data,
  filename,
  fileStatus,
  onRejectReport,
  onApproveReport,
  isReportStatusUpdated,
  onAlternativeChoose,
  onMultiAlternativeChoose,
  country,
  mutateReportContentFn,
}): JSX.Element => {
  const [sameProductsData, setSameProductsData] = useState<SameProductsType[]>(
    []
  );
  const [matchedProductsWithDuplicates, setMatchedProductsWithDuplicates] =
    useState<any | null>(null);
  const [numbersOfSimilarCases, setNumbersOfSimilarCases] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { result: authResult }: any = useAuthFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes.read,
  });
  const { setNewAlert } = useContext(AlertsContext);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  function handleSameProductsSelectReject() {
    setMatchedProductsWithDuplicates(null);
    setNumbersOfSimilarCases(null);
  }

  function handleAlternativeChoose(
    productData: SelectedOptionType,
    fromSmartSearch: boolean
  ) {
    onAlternativeChoose(productData, fromSmartSearch);

    const { initial_product_data, uom, id }: ProductDetailsType =
      productData?.params;

    if (
      !sameProductsData.some(
        (obj: any) =>
          obj?.initial_product_data?.material_number ===
          initial_product_data.material_number
      )
    ) {
      const sameProducts = findSameProducts(
        data,
        sameProductsData,
        initial_product_data.material_number,
        uom,
        initial_product_data.product_name,
        id,
        productData?.value,
        productData?.name
      );

      if (!!sameProducts?.products && sameProducts?.products.length > 0) {
        setSameProductsData((prev: any) => [...prev, sameProducts]);
        setMatchedProductsWithDuplicates({
          materialNumber: initial_product_data.material_number,
          matchedMaterialNumber: productData?.value,
          matchedMaterialName: productData?.name,
        });
        setNumbersOfSimilarCases(sameProducts?.products.length);
      }
    }
  }

  function handleSameProductsApprove(
    materialNumber: any,
    matchedMaterialNumber: any,
    matchedMaterialName: any
  ) {
    const sameProductsObj: SameProductsType | undefined = sameProductsData.find(
      (obj: SameProductsType) =>
        obj?.material_number === materialNumber &&
        obj.matchedMaterialName === matchedMaterialName &&
        obj.matchedMaterialNumber === matchedMaterialNumber
    );

    if (sameProductsObj?.products) {
      const sameProductsArray: Array<any> = sameProductsObj.products;
      const sortedProductsArray: Array<ApprovePayloadObject> =
        sameProductsArray.map((product: any) => {
          return {
            value: sameProductsObj?.matchedMaterialNumber,
            name: sameProductsObj?.matchedMaterialName,
            params: { ...product },
          };
        });
      onMultiAlternativeChoose(sortedProductsArray);
    }
    setMatchedProductsWithDuplicates(null);
  }

  async function handleDeleteRow(rowNumber: number) {
    const filenameWithExtension = `${filename}.csv`;
    const url = `delete_row_from_exception_file?filename=${filenameWithExtension}&row_number=${rowNumber}`;
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${url}`,
    };
    const successMessage = 'Row was deleted successfully';
    const errorMessage = 'Something went wrong, please try again';

    await handleApiRequest(
      fetchParams,
      setLoading,
      setNewAlert,
      successMessage,
      errorMessage,
      mutateReportContentFn
    );
  }

  function openDeleteItemDialog(index: number) {
    setDeleteIndex(index);
    setIsDialogOpen(true);
  }

  function closeDeleteItemDialog() {
    setIsDialogOpen(false);
  }

  function confirmDelete() {
    if (deleteIndex !== null) {
      handleDeleteRow(deleteIndex);
    }
    closeDeleteItemDialog();
  }

  const columnsForDataMappingReport: any[] = [
    {
      field: '#',
      headerName: '#',
      filterable: false,
      valueFormatter: (_: any, params: any) => params.id + 1,
      renderCell: (params: GridRenderCellParams) =>
        params.api.getAllRowIds().indexOf(params.id) + 1,
      flex: 0.1,
    },
    {
      field: 'initial_product_data',
      headerName: 'Material Number',
      flex: 0.5,
      valueGetter: (params: any, row: any) => {
        // Access the nested property safely
        const materialNumber = row?.initial_product_data?.material_number;
        // Check if it's "null" (string) and handle it
        return materialNumber === 'null' ? ' ' : materialNumber || '';
      },
    },
    {
      field: 'initial_product_datas',
      headerName: 'Item Name',
      flex: 1,
      valueGetter: (params: any, row: any) => {
        // Access the nested property safely
        return row?.initial_product_data?.product_name || ''; // Default to empty string if undefined
      },
    },
    {
      field: 'uom',
      headerName: 'UOM',
      flex: 0.3,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      flex: 0.3,
    },
    {
      field: 'alternatives',
      headerName: 'Bayer Mapping',
      disableExport: true,
      valueGetter: (params: any, row: any) => row,
      renderCell: (params: any) => {
        return (
          <MappingAlternativesCell
            params={params.row}
            onAlternativeChoose={handleAlternativeChoose}
            country={country}
            onDeleteBtnClick={openDeleteItemDialog}
          />
        );
      },
      filterOperators: [
        {
          label: 'All', // Rename the label for the filter operator
          value: 'all',
          getApplyFilterFn: (filterItem: any) => {
            return (params: any) => {
              if (params) {
                return params;
              }
            };
          },
        },
        {
          label: 'Alternatives found',
          value: 'alternativesFound',
          getApplyFilterFn: (filterItem: any) => {
            return (params: any) => {
              if (!params?.matched && params?.alternatives.length > 0) {
                return params;
              }
            };
          },
        },

        {
          label: 'Mapped',
          value: 'mapped',
          getApplyFilterFn: (filterItem: any) => {
            return (params: any) => {
              if (params?.matched) {
                return params;
              }
            };
          },
        },

        {
          label: 'Not mapped',
          value: 'notMapped',
          getApplyFilterFn: (filterItem: any) => {
            return (params: any) => {
              if (!params?.matched && !params?.alternatives.length) {
                return params;
              }
            };
          },
        },
      ],
      flex: 1.5,
    },
  ];

  const columnsForReport: any[] = [
    {
      field: '#',
      headerName: '#',
      filterable: false,
      valueFormatter: (_: any, params: any) => params.id + 1,
      renderCell: (params: GridRenderCellParams) =>
        params.api.getAllRowIds().indexOf(params.id) + 1,
      flex: 0.1,
    },
    {
      field: 'material_number',
      headerName: 'Material Number',
      flex: 0.5,
    },
    {
      field: 'product_name',
      headerName: 'Item Name',
      flex: 1,
    },
    {
      field: 'uom',
      headerName: 'UOM',
      flex: 0.3,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      flex: 0.3,
    },
  ];

  const suitableColumns = (fileStatus: ReportStatusType) => {
    if (fileStatus === 'REVIEW') {
      return [...columnsForDataMappingReport];
    } else return [...columnsForReport];
  };

  const disableApproveReportButton = (data: Array<ReportDetailsData>) => {
    if (!data || !data.length) return true;
    return data.some((item) => item.matched === null);
  };

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 132px)', width: '100%' }}>
        <DataGridPro
          loading={loading}
          hideFooterSelectedRowCount
          sx={{
            padding: 0,
            background: 'white',
            fontFamily: 'Helvetica Neue',
            color: '#10384F',
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-filler, & .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header.MuiDataGrid-scrollbarFiller--pinnedRight ':
              {
                backgroundColor: 'rgba(245, 245, 245, 1)',
              },
          }}
          columns={suitableColumns(fileStatus)}
          rows={data}
          rowHeight={52}
          pagination
          slots={{
            toolbar: CustomToolbar,
            pagination: (props) => (
              <CustomDatagridPagination
                {...props}
                pageSizes={[15, 25, 50, 75, 100]}
              />
            ),
          }}
          slotProps={{
            toolbar: {
              onApproveReport: onApproveReport,
              onRejectReport: onRejectReport,
              fileStatus: fileStatus,
              isReportStatusUpdated: isReportStatusUpdated,
              isApproveDisabled: disableApproveReportButton(data),
              fileName: filename,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 15 } },
            pinnedColumns: { right: ['alternatives'] },
          }}
          pageSizeOptions={[15, 25, 50, 75, 100]}
        />
        <MultiSelectorSnackBar
          isOpen={!!matchedProductsWithDuplicates}
          matchedProduct={matchedProductsWithDuplicates}
          numbersOfSimilarCases={numbersOfSimilarCases}
          onClose={handleSameProductsSelectReject}
          onApprove={handleSameProductsApprove}
        />
        <DeleteItemDialog
          opened={isDialogOpen}
          onCancel={closeDeleteItemDialog}
          onDelete={confirmDelete}
        />
      </Box>
    </>
  );
};

export default ReportDetails;
