import { Box, Typography } from '@mui/material';
import ReusableToggle from 'components/ReusableToggle/ReusableToggle';
import { AlertsContext } from 'contexts/AlertsContext';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { sendRequest } from 'fetch/fetch-requests/handleApiRequest';
import ReportsTemplateOnboarding from 'pages/reports-templates-onboarding/ReportsTemplateOnboarding';
import { useContext, useState } from 'react';
import useEnabledReports from 'fetch/fetch-hooks/useEnabledReports';
import { UserDataContext } from 'App';

type EnabledReportsType = {
  inventory: boolean;
  sellout: boolean;
};

type OnboardingReportsProps = {
  reportTitle: string;
  reportType: 'Inventory' | 'Sellout';
};

function OnboardingReports({
  reportTitle,
  reportType,
}: OnboardingReportsProps) {
  const { selectedCountry } = useOnboarding();
  const [isReportTypeActive, setIsReportTypeActive] = useState(true);
  const { loading, setLoading } = useOnboarding();
  const { authResult } = useContext(UserDataContext);
  const {
    data: enabledReports,
    mutate: mutateEnabledReports,
  }: { data: EnabledReportsType | any; isLoading: boolean; mutate: any } =
    useEnabledReports(authResult, selectedCountry?.country);
  const { setNewAlert } = useContext(AlertsContext);

  const isDisabledToggle = (enabledReports: EnabledReportsType) => {
    if (reportType === 'Inventory' && enabledReports?.sellout === false) {
      return true;
    } else if (
      reportType === 'Sellout' &&
      enabledReports?.inventory === false
    ) {
      return true;
    } else return false;
  };

  const handleToggleReportType = async () => {
    setLoading(true);
    const newActiveStatus = +!isReportTypeActive;
    const fetchParams = {
      authResult: authResult,
      method: 'POST' as const,
      url: `${process.env.REACT_APP_API_PYTHON_API}/update_active_status_for_report_columns?country=${selectedCountry?.country}&report_type=${reportType}&report_subtype=default&active=${newActiveStatus}`,
    };
    const successMessage = 'Report type updated successfully.';
    const errorMessage = 'Failed to update settings.';

    const onDataFetchSuccess = async () => {
      mutateEnabledReports(
        {
          ...enabledReports,
          [reportType.toLowerCase()]: !!newActiveStatus,
        },
        { revalidate: false }
      );
      setIsReportTypeActive(!isReportTypeActive);
      setLoading(false);
      setNewAlert({ alertType: 'success', text: successMessage });
    };

    const onDataFetchFailed = () => {
      setLoading(false);
      setNewAlert({ alertType: 'error', text: errorMessage });
    };

    sendRequest(fetchParams, setLoading, onDataFetchFailed, onDataFetchSuccess);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'left',
            padding: '16px 24px',
            fontFamily: 'Helvetica Neue',
            color: '#10384F',
          }}
          variant="h6"
        >
          {reportTitle}
        </Typography>
        <ReusableToggle
          isChecked={isReportTypeActive}
          isDisabled={loading || isDisabledToggle(enabledReports)}
          onToggleChange={handleToggleReportType}
        />
      </Box>
      <ReportsTemplateOnboarding
        reportType={reportType}
        country={selectedCountry?.country}
        tableHeight={'calc(100vh - 132px - 68.5px - 64px)'}
        isDisabledMode={!isReportTypeActive}
        setIsReportTypeActive={setIsReportTypeActive}
      />
    </Box>
  );
}

export default OnboardingReports;
