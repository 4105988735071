import { Switch } from "@mui/material";

type ReusableToggleProps = {
  isChecked: boolean;
  isDisabled: boolean;
  onToggleChange: () => void;
};

export default function ReusableToggle({
  isChecked,
  isDisabled,
  onToggleChange,
}: ReusableToggleProps) {
  return (
    <Switch
      checked={isChecked}
      disabled={isDisabled}
      onChange={onToggleChange}
      size="small"
    />
  );
}
