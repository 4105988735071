import "./DefaultLayout.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Alerts from "components/Alerts/Alerts";
import Header from "components/Header/HeaderComponent";
import SidebarComponent from "components/SidebarComponents/SidebarComponent";
import OnboardingContextProviderWrapper from "contexts/OnboardingContexts/OnboardingContextProviderWrapper";

function DefaultLayout({ children }: any) {
  const location = useLocation();
  const [page, setPage] = useState("dashboard");

  useEffect(() => {
    const { pathname } = location;
    const pageName = pathname.slice(1, pathname.length);
    setPage(pageName.split("/")[0]);
  }, [location]);

  return (
    <OnboardingContextProviderWrapper>
      <div className="default-layout">
        <Header />
        <SidebarComponent
          onPageChoose={(boardName: any) => setPage(boardName)}
          activePage={page}
        />
        <main>
          <Alerts />
          <>{children}</>
        </main>
      </div>
    </OnboardingContextProviderWrapper>
  );
}

export default DefaultLayout;
