import useSWR from 'swr';
import fetchData from 'utils/fetchData';

export default function useReportingPeriodsData(
  selectedCountry: string,
  reportType: string,
  isDefault: boolean,
  authResult: any
) {
  const defaultOption = isDefault ? '1' : '0';

  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_reporting_periods`,
      {
        country: selectedCountry,
        report_type: reportType,
        is_default: defaultOption,
        report_subtype: 'default',
      },
    ],
    fetchData
  );
}
