//base
import React, { useState, useEffect, useContext } from 'react';
import './ReportDetailsPage.scss';
import { PageInfoContext } from '../../contexts/PageInfoContext';

//components
import { getFromLocalStorage } from '../../services/storageInterection';
import ReportDetails from 'components/ReportDetails/ReportDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import useFetchReportsData from '../../fetch/fetch-hooks/reports-hooks/useFetchReportsData';
import useFetchReportContent from '../../fetch/fetch-hooks/reports-hooks/useFetchReportContent';
import { fetchDataForMappingChoice } from 'fetch/fetch-requests/reportsRequests';
import { AlertsContext } from 'contexts/AlertsContext';
import getInteger from 'utils/getInteger';
import { useNavigate } from 'react-router-dom';
import { ApprovePayloadObject } from 'types/reportDetailsTypes';
import { UserDataContext } from 'App';
import { sendRequest } from 'fetch/fetch-requests/handleApiRequest';

type ReportDataType = {
  data: Array<ReportDataObjType>;
};

type ReportDataObjType = {
  Distributor_Name: string;
  country: string;
  distributor_id: number;
  filename: string;
  status: ReportStatus;
  widget_status: string;
};

type ReportStatus =
  | 'MISSING'
  | 'REWORK'
  | 'APPROVED'
  | 'REVIEW'
  | 'PROCESSING'
  | 'SUCCESS';

const ReportDetailsPage: React.FC<any> = (): JSX.Element => {
  const navigate = useNavigate();
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const { setPageInfo } = useContext(PageInfoContext);
  const { authResult } = useContext(UserDataContext);
  const [searchParams] = useSearchParams();
  const filename = searchParams.get('name');
  const distributor = searchParams.get('distributor');
  const country = searchParams.get('country') || '';
  const { data: reportsData, isLoading: isLoadingReportsData } =
    useFetchReportsData(country, authResult);

  const [fileStatus, setFileStatus] = useState<ReportStatus>();
  const {
    data: reportContent,
    isLoading: isReportContentLoading,
    mutate,
  } = useFetchReportContent(filename, fileStatus, selectedCountry, authResult);

  const [isApproveReportLoaded, setApproveReportLoaded] =
    React.useState<boolean>(false);
  const { setNewAlert } = useContext(AlertsContext);
  const [temporaryData, setTemporaryData] = useState<any>();

  const handleSendRequest = async (actionType = 'approve') => {
    setApproveReportLoaded(true);

    const actionUrlPart =
      actionType === 'approve' ? 'move_file_back_to_flow' : 'reject_file';
    const url = `${process.env.REACT_APP_API_PYTHON_API}/${actionUrlPart}?filename=${filename}.csv`;

    const fetchParams = {
      authResult: authResult,
      method: 'POST' as const,
      url: url,
      data: {},
    };

    const onFailure = () => {
      setNewAlert({
        alertType: 'error',
        text: `There is an issue with the attempt to ${actionType} ${filename}`,
      });
      setApproveReportLoaded(false);
    };

    const onSuccess = () => {
      setNewAlert({
        alertType: 'success',
        text: `${filename} was successfully ${actionType === 'approve' ? 'approved' : 'rejected'}`,
      });
      setApproveReportLoaded(false);

      if (actionType === 'approve') {
        setTimeout(() => navigate('/reports'), 3500);
      }
    };

    sendRequest(fetchParams, setApproveReportLoaded, onFailure, onSuccess);
  };

  async function handleApproveReport() {
    handleSendRequest('approve');
  }

  async function handleRejectReport() {
    handleSendRequest('reject');
  }

  useEffect(() => {
    if (filename && distributor) {
      setPageInfo({
        headerContent: `${filename} (${distributor})`,
        selectedPage: 'report',
        selectedTab: 'reports',
      });
    }
  }, [filename, distributor]);

  useEffect(() => {
    if (reportsData?.data) {
      const { status }: ReportDataObjType = reportsData?.data.find(
        (reportData: ReportDataObjType) =>
          reportData?.filename === filename &&
          reportData?.distributor_id === Number(distributor)
      );
      setFileStatus(status);
    }
  }, [reportsData]);

  function handleUpdateTemporaryData(
    data: any,
    statusUpdate: 'success' | 'error' | 'loading',
    isSmartSearchUpdate: boolean | undefined
  ) {
    const result: any = [];
    temporaryData.forEach((obj: any) => {
      if (obj.id === data.id) {
        if (statusUpdate === 'success') {
          obj.matched = data?.matched_material_id;
          obj.product_name = data?.product_name;
          obj.statusUpdate = 'success';
          if (isSmartSearchUpdate) {
            obj.smart_search = [
              {
                material_name: data?.name,
                material_number: data.matched_material_id,
              },
            ];
            obj.matched = data.matched_material_id;
          }
        }
        if (statusUpdate === 'error') {
          obj.statusUpdate = 'error';
        }
        if (statusUpdate === 'loading') {
          obj.statusUpdate = 'loading';
        }
      }
      result.push(obj);
    });

    setTemporaryData(result);
  }

  useEffect(() => {
    if (!reportContent) {
      setTemporaryData([]);
      return;
    }

    const getStatusUpdate = (element: any) => {
      const { matched, alternatives, smart_search } = element;
      if (
        matched &&
        alternatives?.length > 0 &&
        element.statusUpdate !== 'loading'
      ) {
        return 'success';
      }
      if (
        alternatives?.length === 0 &&
        smart_search?.length > 0 &&
        getInteger(matched) === getInteger(smart_search[0].material_number)
      ) {
        return 'success';
      }
      return null;
    };

    if (reportContent) {
      const reportTemporaryData = reportContent.map((element: any) => ({
        ...element,
        statusUpdate: getStatusUpdate(element),
      }));
      setTemporaryData(reportTemporaryData);
    } else setTemporaryData([]);
  }, [reportContent]);

  async function handleAlternativeChoose(
    result: ApprovePayloadObject,
    fromSmartSearch: boolean
  ) {
    const data = {
      id: result.params.id,
      matched_material_id: result?.value,
      country: country,
      product_name: result.name,
      from_smart_search: +fromSmartSearch,
    };

    const requestBody: any = {
      filename: `${filename}.csv`,
      data: [data],
    };

    handleUpdateTemporaryData(data, 'loading', fromSmartSearch);
    const responce = await fetchDataForMappingChoice(requestBody);
    if (responce?.ok) {
      fromSmartSearch
        ? handleUpdateTemporaryData(
            {
              ...data,
              name: result.name,
            },
            'success',
            fromSmartSearch
          )
        : handleUpdateTemporaryData(data, 'success', fromSmartSearch);
      mutate();
    } else {
      handleUpdateTemporaryData(data, 'error', fromSmartSearch);
    }
  }

  async function handleMultiAlternativeChoose(
    result: Array<ApprovePayloadObject>
  ) {
    const resultArr = result.map((obj: any) => {
      return {
        id: obj.params.id,
        matched_material_id: obj?.value,
        country: country,
        product_name: obj.name,
        from_smart_search: 0,
      };
    });

    const requestBody: any = {
      filename: `${filename}.csv`,
      data: resultArr,
    };

    resultArr.forEach((obj: any) => {
      handleUpdateTemporaryData(obj, 'loading', false);
    });
    const responce = await fetchDataForMappingChoice(requestBody);
    if (responce?.ok) {
      resultArr.forEach((obj: any) => {
        handleUpdateTemporaryData(obj, 'success', false);
      });
      mutate();
    } else {
      resultArr.forEach((obj: any) => {
        handleUpdateTemporaryData(obj, 'error', false);
      });
    }
  }

  return (
    <>
      <div className="report-content-page">
        {reportContent && temporaryData && (
          <ReportDetails
            data={temporaryData}
            filename={filename}
            country={country}
            fileStatus={fileStatus}
            isReportStatusUpdated={isApproveReportLoaded}
            onRejectReport={handleRejectReport}
            onApproveReport={handleApproveReport}
            onAlternativeChoose={handleAlternativeChoose}
            onMultiAlternativeChoose={handleMultiAlternativeChoose}
            mutateReportContentFn={mutate}
          />
        )}
        {(isApproveReportLoaded ||
          isLoadingReportsData ||
          isReportContentLoading) && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>
    </>
  );
};

export default ReportDetailsPage;
