import { useState, useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import {
  TooltipContent,
  LightTooltip,
} from 'components/ReportTemplateTable/TemplateModals/Tooltip';
import {
  columnsDataTypesDictionary,
  dataTypesOptions,
} from 'constants/reportTemplatesConstants';
import { ReportTemplateDataType } from 'types/reportTemplates';
import getKeyByValue from 'utils/getKeyByValue';
import useDebounce from 'hooks/useDebounce';
import { isValidNumber } from 'utils/isValidNumber';

const EditModal = ({
  allRowsData,
  open,
  onClose,
  onSave,
  editedRow,
  isUserEMEA,
  isToggleDisabled,
}: {
  allRowsData: ReportTemplateDataType[];
  open: boolean;
  onClose: () => void;
  onSave: (column: any) => void;
  editedRow: ReportTemplateDataType;
  isUserEMEA: boolean;
  isToggleDisabled: boolean;
}) => {
  const {
    data_type: dataType,
    isEDF,
    translated_column_name: originalFieldNameInEnglish,
    column_name: originalFieldNameInLocalLanguage,
    min_value: originalMinValue,
    max_value: originalMaxValue,
    mandatory: originalMandatory,
  }: ReportTemplateDataType = editedRow;

  const originalMinValueString = `${originalMinValue}`;
  const originalMaxValueString = `${originalMaxValue}`;
  const [fieldNameInEnglish, setFieldNameInEnglish] = useState(
    originalFieldNameInEnglish
  );
  const [fieldNameInLocalLanguage, setFieldNameInLocalLanguage] = useState(
    originalFieldNameInLocalLanguage
  );
  const [minValue, setMinValue] = useState(originalMinValueString);
  const [maxValue, setMaxValue] = useState(originalMaxValueString);
  const [isMandatory, setIsMandatory] = useState(originalMandatory);
  const [dataTypeState, setDataType] = useState<string>(dataType);
  const [isEnglishNameDuplicate, setIsEnglishNameDuplicate] = useState(false);
  const [isLocalLanguageNameDuplicate, setIsLocalLanguageNameDuplicate] =
    useState(false);

  const isNumberableDataType =
    dataTypeState === 'Number' || dataTypeState === 'Decimal';

  const debouncedFieldNameInEnglish = useDebounce(fieldNameInEnglish, 300);
  const debouncedFieldNameInLocalLanguage = useDebounce(
    fieldNameInLocalLanguage,
    300
  );
  const debouncedMinValue = useDebounce(minValue, 300);
  const debouncedMaxValue = useDebounce(maxValue, 300);

  const isFieldEmpty = (value: string) => !value.trim();
  const hasDuplicates = isEnglishNameDuplicate || isLocalLanguageNameDuplicate;
  const hasEmptyFields =
    isFieldEmpty(fieldNameInEnglish) || isFieldEmpty(fieldNameInLocalLanguage);
  const hasValidNumberRange = (min: string, max: string) =>
    isValidNumber(min) && isValidNumber(max);
  const hasInvalidNumber =
    isNumberableDataType &&
    (!minValue || !maxValue || !hasValidNumberRange(minValue, maxValue));
  const isFormInvalid = hasEmptyFields || hasDuplicates || hasInvalidNumber;

  const checkIfNameExists = (
    name: string,
    allColumns: ReportTemplateDataType[],
    key: 'translated_column_name' | 'column_name',
    originalName: string
  ) => {
    const trimmedName = name.trim().toLowerCase();
    const originalTrimmedName = originalName.trim().toLowerCase();
    return allColumns.some(
      (column: ReportTemplateDataType) =>
        column[key].trim().toLowerCase() === trimmedName &&
        originalTrimmedName !== trimmedName
    );
  };

  useEffect(() => {
    if (allRowsData) {
      const isEnglishColDuplicate = checkIfNameExists(
        debouncedFieldNameInEnglish,
        allRowsData,
        'translated_column_name',
        originalFieldNameInEnglish
      );
      setIsEnglishNameDuplicate(isEnglishColDuplicate);
    }
  }, [debouncedFieldNameInEnglish, originalFieldNameInEnglish, allRowsData]);

  useEffect(() => {
    if (allRowsData) {
      const isLocalColDuplicate = checkIfNameExists(
        debouncedFieldNameInLocalLanguage,
        allRowsData,
        'column_name',
        originalFieldNameInLocalLanguage
      );
      setIsLocalLanguageNameDuplicate(isLocalColDuplicate);
    }
  }, [
    debouncedFieldNameInLocalLanguage,
    originalFieldNameInLocalLanguage,
    allRowsData,
  ]);

  useEffect(() => {
    setDataType(columnsDataTypesDictionary[dataType]);
  }, []);

  function handleSaveUpdate() {
    onSave({
      column_name: fieldNameInLocalLanguage,
      translated_column_name: fieldNameInEnglish,
      data_type: getKeyByValue(columnsDataTypesDictionary, dataTypeState),
      min_value: isEDF
        ? originalMinValue
        : isNumberableDataType
          ? +minValue
          : 1,
      max_value: isEDF
        ? originalMaxValue
        : isNumberableDataType
          ? +maxValue
          : 1000,
      mandatory: isMandatory,
      originalFieldNameInEnglish: originalFieldNameInEnglish,
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: 445 } }}
    >
      <DialogTitle>Edit</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            padding: '10px',
            '& .MuiInputBase-input, & .MuiButton-outlined': {
              fontFamily: 'Helvetica Neue',
              color: '#10384F',
            },
            '& .MuiButton-outlined': {
              borderColor: 'rgba(0, 0, 0, 0.42)',
            },
          }}
        >
          <TextField
            disabled
            label="Field Name in English"
            variant="outlined"
            value={fieldNameInEnglish}
            onChange={(e: any) => setFieldNameInEnglish(e.target.value)}
            error={isEnglishNameDuplicate}
            helperText={isEnglishNameDuplicate && 'Field name already exists'}
            fullWidth
          />

          <TextField
            disabled={isEDF && !isUserEMEA}
            label="Field Name in Local Language"
            variant="outlined"
            value={fieldNameInLocalLanguage}
            onChange={(e: any) => setFieldNameInLocalLanguage(e.target.value)}
            error={isLocalLanguageNameDuplicate}
            helperText={
              isLocalLanguageNameDuplicate && 'Field name already exists'
            }
            fullWidth
          />

          <TextField
            disabled={isEDF}
            label="Data Type"
            variant="outlined"
            fullWidth
            defaultValue={columnsDataTypesDictionary[dataType]}
            select
            onChange={(e: any) => {
              setDataType(e.target.value);
              setMinValue(originalMinValueString);
              setMaxValue(originalMaxValueString);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ marginRight: '8px' }} position="end">
                  <LightTooltip title={<TooltipContent />} arrow>
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </InputAdornment>
              ),
            }}
          >
            {dataTypesOptions.map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {(dataTypeState === 'Number' || dataTypeState === 'Decimal') && (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <TextField
                disabled={isEDF}
                label="Min Value"
                variant="outlined"
                fullWidth
                value={minValue}
                onChange={(e: any) => setMinValue(e.target.value)}
                error={
                  !isValidNumber(debouncedMinValue) && debouncedMinValue !== ''
                }
                helperText={
                  !isValidNumber(debouncedMinValue) &&
                  debouncedMinValue !== '' &&
                  'Value should be a valid number'
                }
              />
              <TextField
                disabled={isEDF}
                label="Max Value"
                variant="outlined"
                fullWidth
                value={maxValue}
                onChange={(e: any) => setMaxValue(e.target.value)}
                error={
                  !isValidNumber(debouncedMaxValue) && debouncedMaxValue !== ''
                }
                helperText={
                  !isValidNumber(debouncedMaxValue) &&
                  debouncedMaxValue !== '' &&
                  'Value should be a valid number'
                }
              />
            </Box>
          )}
          <FormControlLabel
            disabled={isToggleDisabled}
            onChange={(e: any) => {
              setIsMandatory(e.target.checked);
            }}
            control={<Switch checked={isMandatory} />}
            label="Set as Mandatory"
          />
        </Box>
      </DialogContent>
      <Divider />
      <Box
        fontFamily={'Helvetica Neue'}
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          sx={{
            borderColor: 'rgba(0, 0, 0, 0.42)',
            color: '#10384F',
            fontFamily: 'inherit',
          }}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2, fontFamily: 'inherit' }}
          onClick={handleSaveUpdate}
          disabled={isFormInvalid}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
};

export default EditModal;
