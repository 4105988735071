import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/material";

const CustomizedMUIDaysCheckbox = ({
  onChange,
  isChecked,
  id,
  value,
  label,
}: any) => {
  return (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={onChange} />}
        label={
          value === 0
            ? `${label}`
            : value > 1
              ? `${value} days`
              : `${value} day`
        }
      />
    </Box>
  );
};

export default CustomizedMUIDaysCheckbox;
