import React from "react";

//components
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import CustomizedMUISelector from "customized-mui-elements/CustomizedMUISelector/CustomizedMUISelector";
import DynamicDaysCheckbox from "components/NotificationSettings/NotificationsSelectors/DynamicDaysCheckbox";
import CustomizedMUIDaysCheckbox from "components/NotificationSettings/NotificationsSelectors/CustomizedMUIDaysCheckbox";
import DaySelector from "components/NotificationSettings/DaySelector/DaySelector";

//utils
import generateRandomId from "utils/genereteRandomId.js";
import { textData } from "constants/textConstants";

const AfterReportingDueDate: React.FC<any> = ({
  selectedDailyFrequency,
  onFrequencyChange,
}): JSX.Element => {
  const [frequency, setFrequency] = React.useState<any>("Daily");

  function handleFrequency(option: string) {
    if (option === "Weekly") {
      setFrequency("Weekly");
      onFrequencyChange({ afterReportingDueDate: [0, 1, 2, 3, 4] });
    } else {
      setFrequency("Daily");
    }
  }

  return (
    <div className="after-due-date">
      <h3 className="after-due-date__title">
        {textData.Notifications.Title.AfterReportingDueDay}
      </h3>
      <div className="after-due-date__day-of-week-selector">
        <DaySelector
          selectedDays={selectedDailyFrequency}
          onUpdate={onFrequencyChange}
        />
      </div>
      <div className="after-due-date__selector selector-container">
        <CustomizedMUISelector
          value={frequency}
          data={["Daily", "Weekly"]}
          onUpdate={(e: any) => handleFrequency(e.target.value)}
          label={"Frequency"}
        />
      </div>
    </div>
  );
};

const BeforeReportingDueDate: React.FC<any> = ({
  data,
  onUpdate,
  label = "On Due Day",
}): JSX.Element => {
  function handleSelect(id: any, isSelected: any) {
    const updatedData = data;

    updatedData.forEach((obj: any) => {
      if (obj.id === id) {
        obj.selected = isSelected;
      }
    });

    onUpdate({ beforeReportingDueDate: updatedData });
  }

  function handleAddNewDaysCheckbox() {
    const id = generateRandomId();
    onUpdate({
      beforeReportingDueDate: [
        ...data,
        {
          id,
          label: label,
          selected: false,
          value: 0,
          isEditable: true,
        },
      ],
    });
  }

  function handleDaysCheckboxEdit(id: any, value: number, selected: boolean) {
    const updatedData = [...data];
    updatedData.forEach((obj) => {
      if (obj.id === id) {
        obj.value = value;
        obj.selected = selected;
      }
    });
    onUpdate({ beforeReportingDueDate: [...data] });
  }

  return (
    <div className="before-due-date">
      <h3 className="before-due-date__title">
        {textData.Notifications.Title.BeforeReportingDueDay}
      </h3>
      <div className="before-due-date__checkboxes">
        <FormGroup>
          {data.map(({ selected, value, id, isEditable }: any) =>
            isEditable ? (
              <DynamicDaysCheckbox
                key={id}
                id={id}
                value={value}
                selected={selected}
                onNewElementChecked={handleDaysCheckboxEdit}
                label={label}
              />
            ) : (
              <CustomizedMUIDaysCheckbox
                key={id}
                id={id}
                isChecked={selected}
                value={value}
                label={label}
                onChange={(e: any) => handleSelect(id, e.target.checked)}
              />
            )
          )}
        </FormGroup>
      </div>

      <Button
        variant="outlined"
        sx={{
          fontFamily: "Helvetica Neue",
          color: "#10384F",
          backgroundColor: "#EEEEEE",
          border: "none",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.13)",
            border: "none",
          },
        }}
        onClick={handleAddNewDaysCheckbox}
      >
        Add rule
      </Button>
    </div>
  );
};

const BeforeReportingStartDate: React.FC<any> = ({
  data,
  onUpdate,
  label = "On Start Day",
}): JSX.Element => {
  console.log(data, "test data");

  function handleSelect(id: any, isSelected: any) {
    const updatedData = data;
    updatedData.forEach((obj: any) => {
      if (obj.id === id) {
        obj.selected = isSelected;
      }
    });

    onUpdate({ beforeReportingStartDate: updatedData });
  }

  function handleAddNewDaysCheckbox() {
    onUpdate({
      beforeReportingStartDate: [
        ...data,
        {
          id: generateRandomId(),
          label: label,
          selected: false,
          value: 0,
          isEditable: true,
        },
      ],
    });
  }

  function handleDaysCheckboxEdit(id: any, value: number, selected: boolean) {
    const updatedData = [...data];
    updatedData.forEach((obj) => {
      if (obj.id === id) {
        obj.value = value;
        obj.selected = selected;
      }
    });
    onUpdate({ beforeReportingStartDate: [...data] });
  }

  return (
    <div className="before-start-date">
      <h3 className="before-start-date__title">
        {textData.Notifications.Title.BeforeReportingStartDay}
      </h3>
      <div className="before-due-date__checkboxes">
        <FormGroup>
          {data.map(({ selected, value, id, isEditable }: any) =>
            isEditable ? (
              <DynamicDaysCheckbox
                key={id}
                id={id}
                value={value}
                selected={selected}
                onNewElementChecked={handleDaysCheckboxEdit}
                label={label}
              />
            ) : (
              <CustomizedMUIDaysCheckbox
                key={id}
                id={id}
                isChecked={selected}
                value={value}
                label={label}
                onChange={(e: any) => handleSelect(id, e.target.checked)}
              />
            )
          )}
        </FormGroup>
      </div>

      <Button
        variant="outlined"
        sx={{
          fontFamily: "Helvetica Neue",
          color: "#10384F",
          backgroundColor: "#EEEEEE",
          border: "none",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.13)",
            border: "none",
          },
        }}
        onClick={handleAddNewDaysCheckbox}
      >
        Add rule
      </Button>
    </div>
  );
};

const NotificationRules: React.FC<any> = ({
  selectedPeriod,
  dailyNotifications,
  weeklyNotifications,
  monthlyNotifications,
  quarterlyNotifications,
  customNotifications,

  onDailyNotificationsUpdate,
  onWeeklyNotificationsUpdate,
  onMonthlyNotifications,
  onQuarterlyNotifications,
  onCustomNotifications,
}): JSX.Element => {
  return (
    <div className="notification-rules">
      <h2 className="notification-rules__title">
        {textData.Notifications.Title.NotificationRules}
      </h2>

      <div className="content">
        {selectedPeriod === "Daily" && dailyNotifications && (
          <AfterReportingDueDate
            selectedDailyFrequency={dailyNotifications?.afterReportingDueDate}
            onFrequencyChange={onDailyNotificationsUpdate}
          />
        )}

        {selectedPeriod === "Weekly" && weeklyNotifications && (
          <>
            <BeforeReportingDueDate
              data={weeklyNotifications?.beforeReportingDueDate}
              onUpdate={onWeeklyNotificationsUpdate}
            />
            <AfterReportingDueDate
              selectedDailyFrequency={
                weeklyNotifications?.afterReportingDueDate
              }
              onFrequencyChange={onWeeklyNotificationsUpdate}
            />
          </>
        )}

        {selectedPeriod === "Monthly" && monthlyNotifications && (
          <>
            <BeforeReportingStartDate
              data={monthlyNotifications?.beforeReportingStartDate}
              onUpdate={onMonthlyNotifications}
            />
            <BeforeReportingDueDate
              data={monthlyNotifications?.beforeReportingDueDate}
              onUpdate={onMonthlyNotifications}
            />
            <AfterReportingDueDate
              selectedDailyFrequency={
                monthlyNotifications?.afterReportingDueDate
              }
              onFrequencyChange={onMonthlyNotifications}
            />
          </>
        )}

        {selectedPeriod === "Quarterly" && quarterlyNotifications && (
          <>
            <BeforeReportingStartDate
              data={quarterlyNotifications?.beforeReportingStartDate}
              onUpdate={onQuarterlyNotifications}
            />
            <BeforeReportingDueDate
              data={quarterlyNotifications?.beforeReportingDueDate}
              onUpdate={onQuarterlyNotifications}
            />
            <AfterReportingDueDate
              selectedDailyFrequency={
                quarterlyNotifications?.afterReportingDueDate
              }
              onFrequencyChange={onQuarterlyNotifications}
            />
          </>
        )}

        {selectedPeriod === "Custom" && customNotifications && (
          <>
            <BeforeReportingStartDate
              data={customNotifications?.beforeReportingStartDate}
              onUpdate={onCustomNotifications}
            />
            <BeforeReportingDueDate
              data={customNotifications?.beforeReportingDueDate}
              onUpdate={onCustomNotifications}
            />
            <AfterReportingDueDate
              selectedDailyFrequency={
                customNotifications?.afterReportingDueDate
              }
              onFrequencyChange={onCustomNotifications}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationRules;
