import { useEffect, useMemo, useState } from 'react';
import CustomDialog from 'customized-mui-elements/Dialog/CustomDialog';
import {
  User,
  DataStewardActiveStatus,
  UpdatedUser,
} from 'pages/data-stewards-page/DataStewardsPage';
import DataStewardForm from './DataStewardForm';
import { isValidEmail } from 'utils/isValidEmail';
import useDebounce from 'hooks/useDebounce';
interface AddDataStewardDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (newSteward: UpdatedUser) => void;
  dataStewards: User[];
}

function AddDataStewardDialog({
  open,
  onClose,
  onSave,
  dataStewards,
}: AddDataStewardDialogProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(1);
  const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
  const [role, setRole] = useState('Data Steward');

  const debouncedEmail = useDebounce(email, 300);

  const emailSet = useMemo(
    () =>
      new Set(
        dataStewards.map((steward) => steward.email.toLocaleLowerCase().trim())
      ),
    [dataStewards]
  );

  useEffect(() => {
    const normalizedEmail = debouncedEmail.toLocaleLowerCase().trim();
    const emailIsValid = isValidEmail(debouncedEmail);
    setIsEmailDuplicate(emailIsValid && emailSet.has(normalizedEmail));
  }, [debouncedEmail, emailSet]);

  const handleSave = () => {
    if (name && email) {
      const newSteward: UpdatedUser = {
        name,
        email,
        role,
        active: status as DataStewardActiveStatus,
      };
      onSave(newSteward);
    } else {
      alert('All fields are required.');
    }
  };

  const actions = [
    { text: 'Cancel', handler: onClose },
    { text: 'Save', handler: handleSave },
  ];

  return (
    <CustomDialog
      opened={open}
      title="Add New User"
      actions={actions}
      onClose={onClose}
      isFormInvalid={!isValidEmail(email) || isEmailDuplicate || !name}
    >
      <DataStewardForm
        name={name}
        email={email}
        role={role}
        isValidEmail={isValidEmail(email)}
        isEmailDuplicate={isEmailDuplicate}
        status={status}
        onNameChange={setName}
        onEmailChange={setEmail}
        onStatusChange={setStatus}
        onRoleChange={setRole}
      />
    </CustomDialog>
  );
}

export default AddDataStewardDialog;
