import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/material";

const DynamicDaysCheckbox = ({
  selected,
  value,
  id,
  onNewElementChecked,
  label,
}: any) => {
  const [daysBefore, setDaysBefore] = useState(value);
  const [isChecked, setIsChecked] = useState(false);
  const [numberValue, setNumberValue] = useState(1);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleNumberChange = (event: any) => {
    setNumberValue(event.target.value);
  };

  const handleDaysChange = (event: any) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value > -1) {
      setDaysBefore(value);
      onNewElementChecked(id, value, selected);
    }
  };

  function handleSelect(isSelected: boolean) {
    onNewElementChecked(id, value, isSelected);
  }

  return (
    <Box display="flex" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}
            onChange={(e) => handleSelect(e.target.checked)}
          />
        }
        label={``}
      />
      <input
        className="number-input"
        type="number"
        value={daysBefore}
        onChange={handleDaysChange}
      ></input>
      <span>
        {daysBefore === 0
          ? `${label}`
          : ` day${daysBefore > 1 ? "s" : ""} before`}
      </span>
    </Box>
  );
};

export default DynamicDaysCheckbox;
