import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { useStepper } from 'contexts/OnboardingContexts/StepperContext';
import {
  CountrySuggestion,
  OnboardingCountryStatus,
} from '../../pages/onboarding-page/types';
import fetchData from 'utils/fetchData';
import { useContext } from 'react';
import { AlertsContext } from 'contexts/AlertsContext';
import getTimestamp from 'utils/getTimestamp';
import { useMsal } from '@azure/msal-react';
import { UserDataContext } from 'App';

export function useOnboardingStepperHandlers() {
  const { accounts } = useMsal();
  const user = accounts ? accounts[0] : { name: 'default' };
  const { setNewAlert } = useContext(AlertsContext);
  const { authResult } = useContext(UserDataContext);
  const {
    activeStep,
    maxStep,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    setActiveStep,
    handleNext,
    handleBack,
    setIsOnboardingComplete,
    initialInformationUnsavedChangesDialog,
    onboardingTimelinesUnsavedChangesDialog,
  } = useStepper();
  const {
    selectedCountry,
    setLoading,
    setSelectedCountry,
    setIsCountryOnboarding,
    onboardingCountries,
    setOnboardingCountries,
  } = useOnboarding();

  const handleLeave = (
    proceedFunction: () => void,
    page?: 'Initial information' | 'Timelines'
  ) => {
    if (!hasUnsavedChanges) {
      proceedFunction();
      return;
    }

    if (!page) return;

    switch (page) {
      case 'Initial information':
        initialInformationUnsavedChangesDialog.openDialog();
        break;
      case 'Timelines':
        onboardingTimelinesUnsavedChangesDialog.openDialog();
        break;
      default:
        console.error('Invalid page provided to handleLeave');
        break;
    }
  };

  const handleExit = () => {
    setActiveStep(0);
    setSelectedCountry(null);
    setIsCountryOnboarding(false);
  };

  const handleNavigation = async () => {
    if (activeStep === maxStep - 1) {
      // Final step
      if (selectedCountry) {
        setLoading(true);
        const updateSuccessful = await handleCountryData(
          'update',
          selectedCountry,
          true
        );
        setLoading(false);

        if (updateSuccessful) {
          handleNext(); // Proceed only on successful update
        } else {
          alert('Failed to finalize the update. Please try again.');
        }
        return; // Stop further execution for the final step
      }
    } else {
      // Handling for non-final steps
      handleLeave(saveProgressAndGoNext);
    }
  };

  const saveProgressAndGoNext = () => {
    if (selectedCountry) {
      const currentCountryDetails = onboardingCountries.find(
        (country) => country.country === selectedCountry.country
      );

      if (
        currentCountryDetails &&
        activeStep > currentCountryDetails.last_step
      ) {
        handleCountryData('update', selectedCountry);
      }
      handleNext();
    } else {
      handleNext();
    }
  };

  async function handleCountryData(
    actionType: 'save' | 'update',
    onboardingCountry: CountrySuggestion,
    isFinalStep = false
  ) {
    if (!onboardingCountry.country) return;

    const url = `${process.env.REACT_APP_API_PYTHON_API}/add_update_country`;
    const currentCountryDetails = onboardingCountries.find(
      (country) => country.country === onboardingCountry.country
    );

    const status = isFinalStep
      ? OnboardingCountryStatus.Onboarded
      : actionType === 'save'
        ? OnboardingCountryStatus.InProgress
        : currentCountryDetails?.status;

    const lastStep = isFinalStep
      ? 7
      : actionType === 'update'
        ? Math.max(activeStep, currentCountryDetails?.last_step || 0)
        : 0;

    const requestObject = {
      country: onboardingCountry.country,
      country_old: onboardingCountry.country,
      country_code: onboardingCountry.country_code,
      status: status,
      contributor: user.name,
      last_step: lastStep,
    };

    if (actionType === 'save') {
      setLoading(true);
    }

    try {
      const response = await fetchData([
        authResult,
        'POST',
        url,
        { data: [requestObject] },
      ]);

      if (response.post_data && response.post_data.length > 0) {
        const countryData = response.post_data[0];
        if (activeStep !== 3) {
          setHasUnsavedChanges(false);
        }
        if (actionType === 'save') {
          setNewAlert({
            alertType: 'success',
            text: `Successfully added country onboarding`,
          });
        }
        setOnboardingCountries((prevCountries) => [
          ...prevCountries.filter(
            (prevCountry) => prevCountry.country !== countryData.country
          ),
          { ...countryData, last_modified: getTimestamp() },
        ]);
        if (isFinalStep) {
          setIsOnboardingComplete(true);
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(
        `Error ${actionType === 'save' ? 'adding' : 'updating'} country: `,
        error
      );
      setNewAlert({
        alertType: 'error',
        text: `Failed to ${actionType === 'save' ? 'save' : 'update'} country`,
      });
      return false;
    } finally {
      if (actionType === 'save') {
        setLoading(false);
      }
    }
  }

  const handleExitBtn = (page: 'Initial information' | 'Timelines') =>
    handleLeave(handleExit, page);

  const handlePrevBtn = () => handleLeave(handleBack);
  const handleSaveBtn = () => {
    if (!selectedCountry) return;
    if (activeStep === 0) {
      handleCountryData('save', selectedCountry);
    }
  };

  const saveNewCountryAndExit = async () => {
    if (selectedCountry) {
      initialInformationUnsavedChangesDialog.closeDialog();

      const saveSuccessful = await handleCountryData('save', selectedCountry);

      if (saveSuccessful) {
        handleExit();
      }
    }
  };

  return {
    handleExit,
    handleExitBtn,
    handleNavigation,
    handlePrevBtn,
    handleSaveBtn,
    saveNewCountryAndExit,
    handleCountryData,
  };
}
