import { Box, Typography } from '@mui/material';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import ProductsPage from 'pages/products-page/ProductsPage';

function OnboardingProductsPage() {
  const { selectedCountry } = useOnboarding();

  return (
    <Box sx={{ height: '100%' }}>
      <Typography
        sx={{
          textAlign: 'left',
          padding: '16px 24px',
          fontFamily: 'Helvetica Neue',
          color: '#10384F',
        }}
        variant="h6"
      >
        Products
      </Typography>
      <ProductsPage
        country={selectedCountry?.country}
        tableHeight={'calc(100vh - 132px - 68.5px - 64px)'}
        isOnboardingProductsPage={true}
      />
    </Box>
  );
}

export default OnboardingProductsPage;
