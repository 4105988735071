import { CountrySuggestion, CountryType } from "pages/onboarding-page/types";
import { createContext, useContext, useState } from "react";

type OnboardingCountriesContextType = {
  isCountryOnboarding: boolean;
  setIsCountryOnboarding: (value: boolean) => void;
  selectedCountry: CountrySuggestion | null;
  setSelectedCountry: (country: CountrySuggestion | null) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onboardingCountries: CountryType[];
  setOnboardingCountries: React.Dispatch<React.SetStateAction<CountryType[]>>;
};

const OnboardingCountriesContext = createContext<
  OnboardingCountriesContextType | undefined
>(undefined);

export const useOnboarding = () => {
  const context = useContext(OnboardingCountriesContext);
  if (!context) {
    throw new Error(`useOnboarding must be used within a OnboardingProvider`);
  }
  return context;
};

export const OnboardingCountriesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [onboardingCountries, setOnboardingCountries] = useState<CountryType[]>(
    []
  );
  const [isCountryOnboarding, setIsCountryOnboarding] = useState(false);
  const [selectedCountry, setSelectedCountry] =
    useState<CountrySuggestion | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <OnboardingCountriesContext.Provider
      value={{
        isCountryOnboarding,
        setIsCountryOnboarding,
        selectedCountry,
        setSelectedCountry,
        loading,
        setLoading,
        onboardingCountries,
        setOnboardingCountries,
      }}
    >
      {children}
    </OnboardingCountriesContext.Provider>
  );
};
