import { sendRequest } from './handleApiRequest';

interface AlternativeData {
  id: number;
  matched_material_id: number;
  country: string;
  product_name: string;
}
interface RequestBody {
  filename: string;
  data: AlternativeData[];
}

export async function fetchDataForMappingChoice(requestBody: RequestBody) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_PYTHON_API}/approve_alternative`,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      }
    );

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function handleReportAction(
  authResult: any,
  reportName: string,
  action: string,
  setUpdateLoaded: (value: boolean) => void,
  createResponseHandler: any
) {
  const baseUrl = `${process.env.REACT_APP_API_PYTHON_API}`;
  const url = `${baseUrl}/${action === 'approve' ? 'move_file_back_to_flow' : 'reject_file'}?filename=${reportName}.csv`;
  const fetchParams = {
    authResult: authResult,
    url: url,
    method: 'POST' as const,
    headers: {
      Accept: 'application/json',
    },
    body: '',
  };

  const { onSuccess, onFailure } = createResponseHandler(
    reportName,
    action,
    setUpdateLoaded
  );
  return sendRequest(fetchParams, setUpdateLoaded, onFailure, onSuccess);
}
