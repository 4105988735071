import { OnboardingCountriesProvider } from "./OnboardingCountriesContext";
import { OnboardingUIProvider } from "./OnboardingUIContext";
import { StepperProvider } from "./StepperContext";
import { OnboardingTemplatesContextProvider } from "./OnboardingTemplatesContext";

const OnboardingContextProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <OnboardingCountriesProvider>
      <StepperProvider>
        <OnboardingTemplatesContextProvider>
        <OnboardingUIProvider>{children}</OnboardingUIProvider>
        </OnboardingTemplatesContextProvider>
      </StepperProvider>
    </OnboardingCountriesProvider>
  );
};

export default OnboardingContextProviderWrapper;
