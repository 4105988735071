import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const TemplatesControlPanel = ({ labels, value, onChange }: any) => {
  const tabStyle = {
    fontFamily: 'Helvetica Neue',
    color: '#516E7F',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '.4px',
    padding: '9px 16px',
    '&.Mui-selected': {
      color: '#516E7F',
    },
  };

  return (
    <>
      <Tabs value={value} onChange={onChange} aria-label="basic tabs">
        {labels.map((obj: any) => (
          <Tab
            sx={tabStyle}
            value={obj.number}
            label={obj.label}
            disabled={obj.isDisabled}
          />
        ))}
      </Tabs>
    </>
  );
};

export default TemplatesControlPanel;
