import './App.scss';
import './assets/index.scss';
import Router from './routes/Router';
import { useEffect, createContext, useLayoutEffect, useState } from 'react';
import { SWRConfig } from 'swr';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { protectedResources } from './authConfig';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useFetchWithMsal } from './hooks/useFetchWithMsal';
import ErrorPage from './pages/error/ErrorPage';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from './services/storageInterection';
import { PageInfoContextWrapper } from './contexts/PageInfoContext';
import { AlertsContextWrapper } from './contexts/AlertsContext';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle';
import { DistributorDetailsProvider } from 'contexts/DistributorDetailsContext';
// TODO: This is a temporary solution until a fix on the back-end side is implemented.
import useSWR from 'swr';

const fetcher = ({ url, accessToken }: any) =>
  fetch(url, {
    headers: {
      Authorization: accessToken,
    },
  }).then((res) => res.json());

function useOnboardingCountriesData(auth: any) {
  return useSWR(
    {
      url: `${process.env.REACT_APP_API_PYTHON_API}/get_countries`,
      accessToken: auth?.accessToken,
    },
    fetcher
  );
}
// TODO: This is a temporary solution until a fix on the back-end side is implemented.

type UserDataContextType = {
  pages?: Array<any>;
  countries?: Array<string>;
  userProfile?: any;
  roleName?: string | [];
  isEMEA?: boolean;
  authResult: string;
};

type PageInfoContextType = {
  headerContent?: string;
  selectedPage?: string;
  selectedTab?: string;
};

type userDataType = {
  userProfile: any;
  selectedRole: string;
  userRoles: Array<string>;
};

export const UserDataContext = createContext<UserDataContextType | any>({});
export const PageInfoContext = createContext<PageInfoContextType>({});

// countries: userData?.userProfile?.countries.sort(),
const MainContent = () => {
  const { isLoading, error, data, execute, result: authResult } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes.read,
  });
  const [userData, setUserData] = useState<userDataType>();
  // TODO: This is a temporary solution until a fix on the back-end side is implemented.

  const { data: onboardedCountries } = useOnboardingCountriesData(authResult);
  console.log(onboardedCountries, 'onboardedCountries');
  // TODO: This is a temporary solution until a fix on the back-end side is implemented.

  useEffect(() => {
    const fetchData = async (): Promise<any> => {
      try {
        await execute(
          'GET',
          `${process.env.REACT_APP_API_PYTHON_API}/authorize`,
          null
        );
      } catch (e) {
        console.error('Fetch error:', e);
      }
    };

    fetchData();
  }, [execute]);

  useEffect(() => {
    const preDefinedCountry = getFromLocalStorage('selectedCountry');
    const preSelectedUserRole = getFromLocalStorage('selectedUserRoleToolBox');

    if (
      preSelectedUserRole &&
      data?.userData?.some(
        (obj: any) => obj.roleNames?.[0] === preSelectedUserRole
      )
    ) {
      setUserData({
        userProfile: data?.userData?.find(
          (obj: any) => obj.roleNames?.[0] === preSelectedUserRole
        ),
        selectedRole: preSelectedUserRole,
        userRoles: data?.userData.map((obj: any) => obj.roleNames[0]),
      });

      const countriesForRole: any = data?.userData?.find(
        (obj: any) => obj.roleNames?.[0] === preSelectedUserRole
      )?.countries;

      if (
        countriesForRole &&
        !!preDefinedCountry &&
        countriesForRole.includes(preDefinedCountry)
      ) {
        saveToLocalStorage('selectedCountry', preDefinedCountry);
      } else if (countriesForRole) {
        saveToLocalStorage('selectedCountry', countriesForRole[0]);
      }
    } else {
      const firstUserRole = data?.userData?.[0]?.roleNames?.[0];
      if (firstUserRole) {
        saveToLocalStorage('selectedUserRoleToolBox', firstUserRole);
        setUserData({
          userProfile: data?.userData?.find(
            (obj: any) => obj.roleNames?.[0] === firstUserRole
          ),
          selectedRole: firstUserRole,
          userRoles: data?.userData.map((obj: any) => obj.roleNames[0]),
        });

        const countriesForRole = data?.userData?.find(
          (obj: any) => obj.roleNames?.[0] === firstUserRole
        )?.countries;
        if (
          countriesForRole &&
          !!preDefinedCountry &&
          countriesForRole.includes(preDefinedCountry)
        ) {
          saveToLocalStorage('selectedCountry', preDefinedCountry);
        } else if (countriesForRole) {
          saveToLocalStorage('selectedCountry', countriesForRole[0]);
        }
      }
    }
  }, [data]);

  if (data?.status === '404' || data?.status === '500') {
    return <ErrorPage errorText={data?.description} />;
  }

  if (isLoading) {
    return <ProgressCircle />;
  }

  if (error) {
    return <ErrorPage errorText={error.message} />;
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <PageInfoContextWrapper>
          <UserDataContext.Provider value={{ ...userData, authResult }}>
            <AlertsContextWrapper>
              <DistributorDetailsProvider>
                {userData && <Router status={data?.status} />}
              </DistributorDetailsProvider>
            </AlertsContextWrapper>
          </UserDataContext.Provider>
        </PageInfoContextWrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </div>
  );
};

function App({ instance }: any) {
  useLayoutEffect(() => {
    LicenseInfo.setLicenseKey(
      '8ad050eed05c8b7cb389d134a16d8305Tz04ODUyMyxFPTE3NDQ4ODk0NTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
    );
  }, []);

  return (
    <MsalProvider instance={instance}>
      <SWRConfig>
        <MainContent />
      </SWRConfig>
    </MsalProvider>
  );
}

export default App;
