import { Box, Button } from '@mui/material';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { useOnboardingUI } from 'contexts/OnboardingContexts/OnboardingUIContext';
import { useStepper } from 'contexts/OnboardingContexts/StepperContext';
import ToggleComponent from 'customized-mui-elements/ToggleComponent/ToggleComponent';
import { useOnboardingStepperHandlers } from 'hooks/onboarding-hooks/useOnboardingStepperHandlers';
import OnboardingTimelinesUnsavedChangesDialog from 'pages/onboarding-page/OnboardingTimelinesUnsavedChangesDialog';
type TimelinesOnboardingFooterProps = {
  saveBtnHandler: () => Promise<boolean>;
  isNextBtnDisabled: boolean;
  isDefaultToggleOn: boolean;
  onDefaultToggleChange: (value: boolean) => void;
};

function TimelinesOnboardingFooter({
  saveBtnHandler,
  isNextBtnDisabled,
  isDefaultToggleOn,
  onDefaultToggleChange,
}: TimelinesOnboardingFooterProps) {
  const { handleExitBtn, handlePrevBtn, handleNavigation } =
    useOnboardingStepperHandlers();
  const { loading } = useOnboarding();
  const { hasUnsavedChanges } = useStepper();
  const { nextBtnText, showPrevBtn, showSaveBtn } = useOnboardingUI();
  const { onboardingTimelinesUnsavedChangesDialog } = useStepper();

  return (
    <>
      {onboardingTimelinesUnsavedChangesDialog.isOpen && (
        <OnboardingTimelinesUnsavedChangesDialog
          onClose={onboardingTimelinesUnsavedChangesDialog.closeDialog}
          open={onboardingTimelinesUnsavedChangesDialog.isOpen}
          saveBtnHandler={saveBtnHandler}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 24px',
          background: 'white',
          marginTop: 'auto',
        }}
      >
        <Box>
          <Button
            sx={{
              fontFamily: 'inherit',
              color: '#10384F',
            }}
            variant="text"
            size="medium"
            disabled={loading}
            onClick={() => handleExitBtn('Timelines')}
          >
            EXIT
          </Button>
          {showSaveBtn && (
            <Button
              sx={{ color: '#0091DF', fontFamily: 'inherit' }}
              variant="text"
              size="medium"
              disabled={!hasUnsavedChanges || loading}
              onClick={saveBtnHandler}
            >
              SAVE
            </Button>
          )}
        </Box>
        <Box display={'flex'} gap={'8px'}>
          <div className="notification-control__switcher">
            <ToggleComponent
              checked={isDefaultToggleOn}
              label={'Default settings'}
              onChange={onDefaultToggleChange}
            />
          </div>
          {showPrevBtn && (
            <Button
              sx={{
                fontFamily: 'inherit',
                borderColor: 'rgba(0, 0, 0, 0.42)',
                color: '#10384F',
              }}
              variant="outlined"
              color="inherit"
              size="medium"
              onClick={handlePrevBtn}
              disabled={hasUnsavedChanges}
            >
              PREV
            </Button>
          )}

          <Button
            sx={{ fontFamily: 'inherit' }}
            variant="contained"
            size="medium"
            onClick={handleNavigation}
            disabled={isNextBtnDisabled}
          >
            {nextBtnText}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default TimelinesOnboardingFooter;
