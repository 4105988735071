import CustomDialog from "customized-mui-elements/Dialog/CustomDialog";
import { useOnboardingStepperHandlers } from "hooks/onboarding-hooks/useOnboardingStepperHandlers";
import { useOnboarding } from "contexts/OnboardingContexts/OnboardingCountriesContext";
import { useStepper } from "contexts/OnboardingContexts/StepperContext";

type Action = { text: string; handler: any };

function OnboardingTimelinesUnsavedChangesDialog({
  open,
  onClose,
  saveBtnHandler,
}: {
  open: boolean;
  onClose: () => void;
  saveBtnHandler: () => Promise<boolean>;
}) {
  const { handleExit } = useOnboardingStepperHandlers();
  const { loading, selectedCountry } = useOnboarding();
  const { setHasUnsavedChanges } = useStepper();
  const { handleCountryData } = useOnboardingStepperHandlers();

  const saveTimelinesSettingsAndExit = async () => {
    setHasUnsavedChanges(false);
    onClose();
    const saveSuccessful = await saveBtnHandler(); //replace hardcoded boolean with value from actual save function saveBtnHandler

    if (saveSuccessful) {
      if (selectedCountry) {
        handleCountryData("update", selectedCountry);
      }
      handleExit();
    }
  };

  const actions: Action[] = [
    { text: "Cancel", handler: onClose },
    {
      text: "Save and exit",
      handler: saveTimelinesSettingsAndExit,
    },
  ];

  return (
    <CustomDialog
      disabled={loading}
      isOnboardingTimelinesDialog={true}
      opened={open}
      title="Exit"
      actions={actions}
      onClose={onClose}
      contentText="You have unsaved changes. Are you sure you want to exit?"
    />
  );
}

export default OnboardingTimelinesUnsavedChangesDialog;
