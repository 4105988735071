import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

function CustomDataGridToolbarWithSearch({
  placeholder,
  buttonLabel,
  onButtonClick,
  showColumnButton,
  showFilterButton,
  children,
  fileName
}: {
  placeholder: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  showColumnButton?: boolean;
  showFilterButton?: boolean;
  children?: any;
  fileName?: string;
}) {
  return (
    <GridToolbarContainer
      style={{ padding: '16px', display: 'flex', gap: '16px' }}
    >
      <GridToolbarQuickFilter
        sx={{
          width: 300,
          '& .MuiOutlinedInput-input': {
            fontFamily: 'Helvetica Neue',
            fontSize: '16px',
            lineHeight: '24px',
          },
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
        }}
        label="Search"
        size="small"
        variant="outlined"
        placeholder={placeholder}
      />
      {showFilterButton && <GridToolbarFilterButton />}
      {showColumnButton && <GridToolbarColumnsButton />}
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName
        }}
      />
      {buttonLabel && onButtonClick && (
        <Button
          sx={{
            marginLeft: 'auto',
            fontFamily: 'Helvetica Neue',
            lineHeight: '24px',
          }}
          variant="contained"
          color="primary"
          size="medium"
          onClick={onButtonClick}
        >
          {buttonLabel}
        </Button>
      )}
      {children}
    </GridToolbarContainer>
  );
}

export default CustomDataGridToolbarWithSearch;
